import axios from 'axios';
import getCookie from '../hooks/get.cookie';

//export const BASE_URL = "http://localhost:8080";
export const BASE_URL = "http://191.101.71.26:8080";

const token = null;
const jwtToken = getCookie('jwt_authorization');

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: jwtToken ? `Bearer ${jwtToken}` : null
  }
});

export const setToken = (newToken) =>{
  token = 'Bearer ' + newToken
}


