import { Card } from "primereact/card"
import { Button } from 'primereact/button'
import { useParams } from "react-router-dom"

export const ProductsStatePage = () => {

  const { state } = useParams()

  return (
    <div>
      <h2 className='text-2xl font-medium mb-2'>Productos {state === 'espera' ? 'en' : state === 'retirar' ? 'para' : ''} {state}</h2>
      <span>Mantén un registro adecuado de aquellos productos que no estan en buenas condiciones</span>

      <div className="lg:flex gap-5 mt-5">
        <div className="lg:w-1/6">
          <Card className="!shadow-none border"></Card>
        </div>
        <div className="lg:w-5/6 grid grid-cols-2 gap-5">
          <Card className="!shadow-none border mb-5">
            <div className="flex gap-3">
              <span>10/12/2024</span>|
              <p>Lunchera Think Happy Prints rainbows holograma correa desmontable</p>
              <p>Código: 43505967</p>
            </div>
            <hr className="my-1"></hr>
            <div className="font-medium gap-3 pl-28">
              <div className="flex gap-3">
                <p>De: <span className="font-normal">PARTICULAR</span></p>
                <p>Cliente: <span className="font-normal">Agustín</span></p>
                <p>Contacto: <span className="font-normal">1157815934</span></p>
              </div>
              <p>Problema: <span className="font-normal">Se rompio la correa</span></p>
            </div>
            <div className='flex justify-end font-medium gap-5'>
              <button className='hover:cursor-pointer hover:text-red-500'>Cancelar</button>
              <div className='hover:cursor-pointer hover:text-blue-500'>Mover a En reparación</div>
            </div>
          </Card>
          <Card className="!shadow-none border mb-5">
            <div className="flex gap-3">
              <span>10/12/2024</span>|
              <p>Lunchera Think Happy Prints rainbows holograma correa desmontable</p>
              <p>Código: 43505967</p>
            </div>
            <hr className="my-1"></hr>
            <div className="font-medium gap-3 pl-28">
              <div className="flex gap-3">
                <p>De: <span className="font-normal">PARTICULAR</span></p>
                <p>Cliente: <span className="font-normal">Agustín</span></p>
                <p>Contacto: <span className="font-normal">1157815934</span></p>
              </div>
              <p>Problema: <span className="font-normal">Se rompio la correa</span></p>
            </div>
            <div className='flex justify-end font-medium gap-5'>
              <button className='hover:cursor-pointer hover:text-red-500'>Cancelar</button>
              <div className='hover:cursor-pointer hover:text-blue-500'>Mover a En reparación</div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}