import { useContext, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { Clock, PackageCheck, PackageX, Store, Warehouse } from 'lucide-react'

import UserContext from '../user.context'
import ShopService from '../services/shop.service'

const shopService = new ShopService()

export const AdminPage = () => {

  const [user] = useContext(UserContext)
  const [sucursales, setSucursales] = useState([])

  useEffect(() => {
    shopService.getAll().then(data => {
      setSucursales(data)
    })
  }, [])

  const shops = sucursales.filter(shop => shop.type === 'LOCAL');
  const depositos = sucursales.filter(deposito => deposito.type === 'DEPOSITO');

  return (
    <div>
      <div class="bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 text-center rounded-md mb-5 p-5 md:flex justify-between items-center">
        <h1 class="text-xl !text-white mb-1 md:mb-0">Bienvenido {user.sub}</h1>
        <span class="text-sm text-white">{user.rol.split('_')[1]}</span>
      </div>

      <div>
        <h2 className='text-xl mb-3'>Locales</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-5'>
          {shops.map((shop, index) => (
            <Link key={index} to={`/local/${shop.name}`}>
              <div className='panel !mb-0 hover:!shadow-xl'>
                <div className='panel-body flex items-center'>
                  <div className='bg-blue-500 rounded-md p-3'>
                    <Store className='text-white m-auto' size={60} />
                  </div>
                  <div className='px-5 my-3'>
                    <h3 className='text-blue-500 text-lg font-medium mb-1'>{shop.name}</h3>
                    <p className='text-gray-500'>Dirección: {shop.address}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div>
        <h2 className='text-xl mb-3'>Depósitos</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-5'>
          {depositos.map((deposito, index) => (
            <Link key={index} to={`/deposito/${deposito.name}`}>
              <div className='panel !mb-0 hover:!shadow-xl'>
                <div className='panel-body flex items-center'>
                  <div className='bg-blue-700 rounded-md p-3'>
                    <Warehouse className='text-white m-auto' size={60} />
                  </div>
                  <div className='px-5 my-3'>
                    <h3 className='text-blue-700 text-lg font-medium mb-1'>{deposito.name}</h3>
                    <p className='text-gray-500'>Dirección: {deposito.address}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div>
        <h2 className='text-xl mb-3'>Otros</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
          <Link to={`/productos/fallados`}>
            <div className='panel !mb-0 hover:!shadow-xl'>
              <div className='panel-body flex items-center'>
                <div className='bg-blue-900 rounded-md p-3'>
                  <PackageX className='text-white m-auto' size={60} />
                </div>
                <div className='px-5 my-3'>
                  <h3 className='text-blue-900 text-lg font-medium mb-1'>Fallados</h3>
                  <p className='text-gray-500'>Tús productos fallados</p>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/productos/espera`}>
            <div className='panel !mb-0 hover:!shadow-xl'>
              <div className='panel-body flex items-center'>
                <div className='bg-blue-900 rounded-md p-3'>
                  <Clock className='text-white m-auto' size={60} />
                </div>
                <div className='px-5 my-3'>
                  <h3 className='text-blue-900 text-lg font-medium mb-1'>En espera</h3>
                  <p className='text-gray-500'>Productos en reparación</p>
                </div>
              </div>
            </div>
          </Link>

          <Link to={`/productos/retirar`}>
            <div className='panel !mb-0 hover:!shadow-xl'>
              <div className='panel-body flex items-center'>
                <div className='bg-blue-900 rounded-md p-3'>
                  <PackageCheck className='text-white m-auto' size={60} />
                </div>
                <div className='px-5 my-3'>
                  <h3 className='text-blue-900 text-lg font-medium mb-1'>Para retirar</h3>
                  <p className='text-gray-500'>Contacta con tus clientes</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}