import React from 'react';
import versions from '../notes.json'

import { Tag } from 'primereact/tag'
import { Card } from 'primereact/card'

export const NewsPage = () => {

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Novedades</h2>
        <span className='text-sm'>Entérate de las últimas actualizaciones de tu sistema.</span>
      </div>

      {versions.map((version, index) => (
        <div key={index} className='panel'>
          <div className='panel-heading'>
            <span>Notas de versión - {version.number} ({version.date})</span>
          </div>

          <div className='panel-body'>
            <div className='mb-3'>
              <p className='text-[#348FE2] text-[15px] font-medium'>Novedades</p>
              <div className='border-l-4 border-[#348FE2] pl-7'>
                <ul className='mt-3'>
                  {version.news.map((newItem, newIndex) => (
                    <li key={newIndex}>
                      <p className='text-sm'>{newItem.item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='mb-3'>
              <p className='text-[#49B6D6] font-medium'>Arreglos</p>
              <div className='border-l-4 border-[#49B6D6] pl-7'>
                <ul className='mt-3'>
                  {version.fixes.map((fixItem, fixIndex) => (
                    <li key={fixIndex}>
                      <p className='text-sm'>{fixItem.item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              <p className='text-[#F59C1A] font-medium'>Comentarios</p>
              <div className='border-l-4 border-[#F59C1A] pl-7'>
                <ul className='mt-3'>
                  {version.comments.map((commentItem, commentIndex) => (
                    <li key={commentIndex}>
                      <p className='text-sm'>{commentItem.item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
