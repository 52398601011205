import axios from "axios"

import { BASE_URL, config } from "../helper/axion.helper"

const baseUrl = BASE_URL + "/api/v1/settings"

class SettingsService {

  getConfig() { return axios.get(baseUrl + "/getConfig").then(res => res.data) }

  getAdditional() { return axios.get(baseUrl + "/getAdditional").then(res => res.data) }
  
  updateSettings(settings) { return axios.put(baseUrl + "/updateSettings", settings).then(res => res.data) }

}

export default SettingsService;