import { useEffect, useState } from 'react'
import SockJS from 'sockjs-client'

import { Tag } from 'primereact/tag'
import { Search, UserCircle2 } from 'lucide-react'

import UserService from '../../services/user.service'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

const userService = new UserService()

export const ChatPage = () => {
  const [listUsers, setListUsers] = useState([])
  const [userData, setUserData] = useState({
    message: "",
    username: "",
    receiverName: ""
  })

  const [messages, setMessages] = useState([])

  useEffect(() => {
    userService.getAll().then(data => {
      setListUsers(data)
    })
  }, [])

  /*useEffect(() => {
    const socket = new SockJS('http://localhost:8080/ws')
    const client = Stomp.over(socket)

    client.connect({}, () => {
      client.subscribe('/user/' + 'pepito' + '/private', onPrivateMessageReceived)
    })
  }, [])*/

  return (

    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Mensajería</h2>
        <span className='text-sm'>Envía y recibe mensajes internos a los miembros de manera eficiente y en tiempo real.</span>
      </div>

      <div class="flex gap-5 overflow-hidden h-[740px]">
        <div class="panel w-1/4 bg-white border-r border-gray-300">
          <div class="panel-heading">
            <span class="font-medium">Usuarios</span>
          </div>

          <div class="panel-body overflow-y-auto h-[790px]">
            {listUsers.map((user) => (
              <div class="flex items-center cursor-pointer hover:bg-gray-100 p-2 border-b">
                <div class="flex-1">
                  <div className="flex justify-between">
                    <span class="font-medium">{user.username}</span>
                    <Tag severity="success" value="En línea" className='!rounded'></Tag>
                  </div>
                  <p class="text-sm text-gray-600">Mensaje de prueba</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div class="panel flex-1 relative">
          <div class="panel-heading">
            <span class="text-xs font-medium">Sujeto 1</span>
          </div>

          <div class="panel-body overflow-y-auto h-[790px] bg-blue-100">
            <div class="flex mb-4">
              <div className="text-start">
                <p class="text-md bg-white rounded p-2">Mensaje de prueba</p>
                <span className="text-sm">10/06/07 15:24</span>
              </div>
            </div>

            <div class="flex justify-end mb-4">
              <div className="text-end">
                <p class="text-md bg-blue-500 text-white rounded p-2">Mensaje de prueba</p>
                <span className="text-sm">10/06/07 15:24</span>
              </div>
            </div>
          </div>

          <div class="bg-white border p-4 absolute bottom-0 w-full">
            <div class="flex items-center gap-3">
              <InputText type="text" placeholder="Escribe un mensaje..." className="p-inputtext-sm w-full !pl-3 focus:outline-none focus:border-blue-500"/>
              <Button label='Enviar' className='!rounded w-auto'></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}