import { useContext, useEffect, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import { Tag } from 'primereact/tag'
import { Card } from 'primereact/card'
import { Toast } from 'primereact/toast'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import { Mail, MailOpen, Trash2 } from 'lucide-react'

import { usePagination } from '../hooks/use.pagination'

import UserContext from '../user.context'
import SalesFilters from '../utils/filters.sale'
import NotificationService from '../services/notification.service'
import { Link } from 'react-router-dom'
import FileService from '../services/file.service'
import { Button } from 'primereact/button'

const fileService = new FileService()
const notificationService = new NotificationService()

export const NotificationsPage = () => {

  const toast = useRef(null)
  const [user] = useContext(UserContext)

  const initialPagination = {
    page: 0,
    shop: 'TODOS',
    recordsQuantity: 50
  }

  const [rows, setRows] = useState(50)
  const [first, setFirst] = useState(0)
  const [showAllRead, setShowAllRead] = useState(false)
  const [totalElements, setTotalElements] = useState(null)

  const [listNotifications, setListNotifications] = useState([])
  const [selectedNotifications, setSelectedNotifications] = useState([])

  const { paginationState, onDropdownChange, setPaginationState } = usePagination(initialPagination)
  const { shop } = paginationState
  const { shops } = SalesFilters()

  useEffect(() => {
    loadNotifications()
  }, [paginationState, showAllRead])

  const loadNotifications = () => {
    const request = { ...paginationState, shop: shop !== 'TODOS' ? shop : '' }

    if (!showAllRead) {
      notificationService.getAllByUserAndShop(request).then(data => {
        setListNotifications(data.content)
        setTotalElements(data.totalElements)
      })
    } else {
      notificationService.getAllReadByUserAndShop(request).then(data => {
        setListNotifications(data.content)
        setTotalElements(data.totalElements)
      })
    }
  }

  const downloadPdf = async (dateTime) => {

  }

  const renderSubject = (rowData) => {
    const subject = rowData.subject

    const subjectSeverities = {
      'lista de precios': 'success',
      'error en el stock': 'danger',
      'stock sin registrar': 'warning',
    }

    const defaultSeverity = 'primary'
    const severity = subject ? subjectSeverities[subject.toLowerCase()] : defaultSeverity

    return <Tag value={subject} severity={severity} className='!rounded' />
  }

  const renderRedirection = (rowData) => {
    const string = rowData.message
    const regex = /código:\s*([^\s]+)/
    const match = string.match(regex)

    if (match && match[1]) {
      const code = match[1];
      const productCode = encodeURIComponent(code)
      return <Link to={`/producto/buscar/${productCode}`} target='_blank'>Ver producto</Link>
    }

    const regex2 = /descarga\s*([^\s]+)/
    const match2 = string.match(regex2)

    if (match2 && match2[1]) {
      return <Link onClick={() => downloadPdf(rowData.dateTime)}>Descargar</Link>
    }

    return ''
  }

  const renderDate = (rowData) => {
    const dateObject = DateTime.fromISO(rowData.dateTime, { zone: 'America/Argentina/Buenos_Aires' });
    const formattedDate = dateObject.setLocale('es').toFormat('dd LLL - HH:mm');
    return formattedDate;
  }

  const renderRead = (rowData) => {
    const dateObject = DateTime.fromISO(rowData.read, { zone: 'America/Argentina/Buenos_Aires' });
    const formattedDate = dateObject.setLocale('es').toFormat('LLL dd');
    return formattedDate;
  }

  const onPageChange = (event) => {
    setRows(event.rows)
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const handleDelete = async (id) => {
    notificationService.delete(id).then(() => {
      loadNotifications()
      showToast('Sistema', 'Notificación eliminada con éxito', toast, 'success')
    })
  }

  const handleMarkAsRead = async (id) => {
    notificationService.markAsRead(id).then(() => {
      loadNotifications()
      showToast('Sistema', 'Notificación marcada como leída', toast, 'success')
    })
  }

  const handleDeleteSelected = () => {
    notificationService.deleteSelected(selectedNotifications).then(() => {
      loadNotifications()
      setSelectedNotifications([])
      showToast('Sistema', 'Notificaciónes eliminadas con éxito', toast, 'success')
    })
  }

  const handleMarkSelectedAsRead = () => {
    notificationService.markSelectedAsRead(selectedNotifications).then(() => {
      loadNotifications()
      setSelectedNotifications([])
      showToast('Sistema', 'Notificaciónes marcadas como leída', toast, 'success')
    })
  }

  const showToast = (title, msg, ref, severity) => {
    const summary = title
    const message = msg
    ref.current.show({ severity: severity, summary: summary, detail: message, life: 3000 })
  }

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Notificaciones {showAllRead && 'leídas'}</h2>
        <span className='text-sm'>Explora tus notificaciones de manera organizada.</span>
      </div>

      <div className='panel'>
        <div className='panel-heading'>
          <span className='font-medium'>Bandeja de entrada</span>

          <div>
            {showAllRead ?
              <div className='flex gap-3 hover:cursor-pointer' onClick={(e) => setShowAllRead(false)}>
                <span className='font-medium'>Ver notificaciones</span>
              </div>
              :
              <div className='flex gap-3 hover:cursor-pointer' onClick={(e) => setShowAllRead(true)}>
                <span className='font-medium'>Ver notificaciones leídas</span>
              </div>
            }
          </div>
        </div>
        <div className='panel-body'>
          <div className='md:flex justify-between items-center mb-3 pr-6'>
            {user.rol === 'ROLE_ADMINISTRADOR' &&
              <div className='flex gap-3 mb-3 md:mb-0'>
                <div className='bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 rounded-md flex justify-center items-center'>
                  <label className='font-medium !text-white px-5'>LOCAL</label>
                </div>
                <Dropdown options={shops} value={shop}
                  name='shop' onChange={onDropdownChange} className='p-inputtext-sm' />
              </div>
            }

            {selectedNotifications.length !== 0 &&
              <div className='flex items-center gap-4'>
                <span>{selectedNotifications.length} seleccionadas</span>
                <div className='flex gap-1'>
                  {!showAllRead &&
                    <Button onClick={handleMarkSelectedAsRead}
                      className='!rounded' severity='primary' size='small'>
                      <i className='bi bi-envelope-open-fill'></i>
                    </Button>
                  }
                  <Button onClick={handleDeleteSelected}
                    className='!rounded' severity='danger' size='small'>
                    <i className='bi bi-trash-fill'></i>
                  </Button>
                </div>
              </div>
            }
          </div>

          <div className='max-h-[530px] overflow-y-auto'>
            <DataTable value={listNotifications} tableStyle={{ minWidth: '60rem' }} scrollable scrollHeight="450px" stripedRows emptyMessage='No hay notificaciones por el momento'
              selectionMode={'checkbox'} selection={selectedNotifications} onSelectionChange={(e) => setSelectedNotifications(e.value)} size='small' dataKey='id'>
              <Column selectionMode='multiple' style={{ width: '1%' }} />
              <Column field='from' header='De' className='font-medium' style={{ width: '5%' }} />
              <Column field='subject' header='Asunto' style={{ width: '9%' }} body={renderSubject} />
              <Column field='message' style={{ width: '72%' }}
                body={(rowdata) => (
                  <p>{rowdata.message} <span className='text-blue-500'>{renderRedirection(rowdata)}</span></p>
                )}>
              </Column>
              <Column field='dateTime' header='Recibido' className='font-medium' style={{ width: 'auto' }} body={renderDate} />
              <Column alignHeader={'center'} style={{ width: '5%' }}
                body={(rowData) => (
                  <div className='flex justify-end gap-1'>
                    {!showAllRead &&
                      <Button onClick={() => handleMarkAsRead(rowData.id)} className='!rounded' severity='primary' size='small'>
                        <i className='bi bi-envelope-open-fill'></i>
                      </Button>
                    }
                    <Button onClick={() => handleDelete(rowData.id)} className='!rounded' severity='danger' size='small'>
                      <i className='bi bi-trash-fill'></i>
                    </Button>
                  </div>
                )}>
              </Column>
            </DataTable>
          </div>

          <Paginator first={first} rows={rows} totalRecords={totalElements}
            pageLinkSize={1} onPageChange={onPageChange} className='mt-5 !p-0' />
        </div >
      </div>

      <Toast ref={toast} position='bottom-right' />
    </div >
  )
}