import { useState, useRef } from 'react';
import JsBarcode from 'jsbarcode';

export const useBarcodeUtils = () => {
  
  const [barcode, setBarcode] = useState(null);
  const [base64Barcode, setBase64Barcode] = useState(null);

  const canvasRef = useRef(null);

  const generateBarcode = () => {
    if (barcode === null) {
      const randomBarcode = generateRandomEAN13()
      setBarcode(randomBarcode)
      JsBarcode("#barcode-svg", randomBarcode, { format: "ean13" })
    } else {
      JsBarcode("#barcode-svg", barcode, { format: "ean13" })
    }

    generateBarcodeImg()
  }

   // Convert barcode to base64
  const generateBarcodeImg = () => {
    const canvas = canvasRef.current;
    const svgElement = document.getElementById('barcode-svg');

    if (!canvas || !svgElement) return;

    canvas.width = svgElement.clientWidth;
    canvas.height = svgElement.clientHeight;
    const context = canvas.getContext('2d');
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const svgImage = new Image();

    svgImage.src = 'data:image/svg+xml;base64,' + btoa(svgString);
    svgImage.onload = function () {
      context.drawImage(svgImage, 0, 0);
      const png = canvas.toDataURL('image/png');
      const base64barcode = png.split(',')[1];
      
      setBase64Barcode(base64barcode)
    }
  }

  const deleteBarcode = () => {
    setBarcode(null)
    setBase64Barcode(null)
    document.getElementById("barcode-svg").innerHTML = ""
  }

  const generateRandomEAN13 = () => {
    const randomNumber = Math.floor(Math.random() * 1000000000000).toString().padStart(12, '0');

    let sum = 0;
    for (let i = 0; i < 12; i++) {
      const digit = parseInt(randomNumber[i]);
      sum += i % 2 === 0 ? digit : digit * 3;
    }

    const checksum = (10 - (sum % 10)) % 10;
    return randomNumber + checksum;
  }

  return {
    barcode,
    canvasRef,
    base64Barcode,
    setBarcode,
    deleteBarcode,
    generateBarcode,
    setBase64Barcode,
  };
};