import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Swal from 'sweetalert2'
import { addLocale } from 'primereact/api'

import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'

import { useForm } from '../../hooks/use.form'
import { useImageUtils } from './components/img.utils'
import { useBarcodeUtils } from './components/barcode.utils'
import { regionalCalendar } from '../../helper/regional.calendar'

import ShopService from '../../services/shop.service'

import ProductService from '../../services/product.service'
import SettingsService from '../../services/settings.service'
import ProductFormFilters from '../../utils/filters.product.form'
import SpecificationsComponent from './components/specifications.form'

const initialProduct = {
  code: '',
  description: '',
  brand: null,
  category: null,
  provider: null,
  cashPrice: 0,
  debitPrice: 0,
  creditPrice: 0,
  purchaseDate: null,
  purchaseQuantity: 0,
}

const shopService = new ShopService()
const productService = new ProductService()
const settingsService = new SettingsService()

export const ProductCreatePage = () => {

  addLocale('es', regionalCalendar)

  const { id } = useParams()
  const navigate = useNavigate()

  const [code, setCode] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [additional, setAdditional] = useState(0)
  const [listStocks, setListStocks] = useState([])
  const [specifications, setSpecifications] = useState([]);

  const { formState, onInputChange, onDropdownChange, handleDate, setFormState } = useForm(initialProduct)
  const { description, brand, category, provider, debitPrice, creditPrice, cashPrice, purchaseDate, purchaseQuantity } = formState

  const { listProviders, listCategories, listBrands } = ProductFormFilters()
  const { base64Image, selectedImage, imgPreviewSrc, handleImageChange } = useImageUtils();
  const { canvasRef, barcode, base64Barcode, setBarcode, deleteBarcode, generateBarcode } = useBarcodeUtils();

  useEffect(() => {
    if (id) {
      productService.getById(id)
        .then((data) => {
          setFormState(prevState => ({
            ...prevState,
            description: data.description,
            brand: data.brand,
            category: data.category,
            provider: data.provider,
            cashPrice: data.cashPrice,
            debitPrice: data.debitPrice,
            creditPrice: data.creditPrice,
          }))

          setSpecifications(data.specifications)
        })
    }

    shopService.getAll().then(data => {
      setListStocks(data.map(shop => ({
        shop: shop.name,
        direction: shop.address,
        quantity: 0
      })))
    })

    settingsService.getAdditional().then(data => {
      setAdditional(data)
    })
  }, []);

  // Code Validation
  useEffect(() => {
    if (code !== '') {
      productService.getByCode(code).then((data) => {
        setErrorCode('Ya existe un producto con este código')
      }).catch(() => {
        setErrorCode('')
      })
    }
  }, [code]);

  // Setting prices
  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      debitPrice: cashPrice,
      creditPrice: cashPrice * (1 + (additional / 100))
    }))
  }, [cashPrice])

  const generateCode = () => {
    const randomNumber = Math.floor(Math.random() * 90000000) + 10000000
    setCode(randomNumber.toString())
  }

  // Handle stock 
  const handleQuantityChange = (rowData, newValue) => {
    const updatedList = listStocks.map(item => {
      if (item.shop === rowData.shop) {
        return { ...item, quantity: newValue }
      }
      return item
    })

    setListStocks(updatedList)
  }

  // Función para manejar la actualización del estado del formulario en el componente padre
  const handleSpecificationsChange = (formState) => {
    setSpecifications(formState);
  };

  const handleCreateProduct = () => {
    if (code === '') {
      setErrorMsg('El código del producto es obligatorio.');
      return;
    }

    if (description === '') {
      setErrorMsg('El campo descripción es obligatorio.');
      return;
    }

    const product = {
      ...formState,
      code: code,
      stocks: listStocks,
      barcodeNumber: barcode,
      base64Image: base64Image,
      base64barcode: base64Barcode,
      specifications: specifications
    }

    productService.create(product).then(data => {
      Swal.fire('Registrado', 'Se ha registrado el producto:<br>' + '"' + data.description + '"', 'success').then(() => {
        navigate('/productos')
      })
    }).catch((error) => {
      Swal.fire('Error', 'Ocurrió un error al intentar crear el producto', 'error')
    })
  }

  return (
    <div className='xl:w-[1140px] m-auto'>
      <div class="bg-[#2A499B] text-center rounded mb-[15px] p-3">
        <h2 class="text-2xl !text-white">Registrar nuevo producto</h2>
      </div>

      <div className='md:flex gap-5'>
        <div className='md:w-1/3'>
          <div className='panel'>
            <div className='panel-body'>
              <span className='text-[#1d64d8] text-lg font-medium'>Imagen del producto</span>
              <div className='my-3'>
                <input className='block w-full text-slate-500 file:rounded file:mr-3 file:py-1 file:px-2 file:border-0
                file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 mb-3'
                  type='file' accept='image/*' onChange={handleImageChange} />
                {selectedImage ?
                  <div className='h-[300px] w-full'>
                    <img id='imgPreview' src={imgPreviewSrc} alt='Preview' className='w-full h-full rounded' />
                  </div> :
                  <div className='h-[300px] w-full'>
                    <img src='/producto-sin-foto.jpg' alt='img' className='w-full h-full rounded'></img>
                  </div>
                }
              </div>
              <span className='text-[#1d64d8] text-lg font-medium'>Código de barras</span>
              <div className='mt-3'>
                <InputNumber value={barcode} onChange={(e) => setBarcode(e.value)} useGrouping={false}
                  className='w-full p-inputtext-sm mb-3' placeholder='Número de código de barras' />
                <div className='border border-1 mb-3'>
                  <svg id='barcode-svg' className='m-auto' width='234px' height='142px'></svg>
                  <canvas ref={canvasRef} style={{ display: 'none' }} />
                </div>
                <div className='flex'>
                  <Button label='Generar' onClick={generateBarcode} className='w-full !me-3 hover:!bg-blue-600' size='small' />
                  <Button label='Eliminar' onClick={deleteBarcode} className='w-full' severity='secondary' size='small' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-1'>
          <div className='border-b border-[#1d64d8] mb-3'>
            <h3 className='text-[#1d64d8] text-lg font-medium pb-1'>Características generales</h3>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <label htmlFor='description' className='block font-medium mb-1'>Código universal / Nro ° Artículo</label>
              <div className='flex mb-3'>
                <InputText name='code' value={code} onChange={(e) => setCode(e.target.value)}
                  className={`p-inputtext-sm w-full !me-3 ${errorCode ? '!border-rose-600' : ''}`} placeholder='Ej: 12-345AB' />
                <div>
                  <Button label='Generar' onClick={generateCode} size='small' className='w-full' />
                </div>
              </div>
              {errorCode && <div className='mb-3'><span className='text-red-500'>{errorCode}</span></div>}
              <div className='mb-3'>
                <label htmlFor='description' className='block font-medium mb-1'>Descripción</label>
                <InputText placeholder='Valija mediana roja' className='p-inputtext-sm w-full'
                  name='description' value={description} onChange={onInputChange} />
              </div>
              <div className='grid grid-cols-3 gap-3'>
                <div className='md:mb-3'>
                  <label htmlFor='provider' className='block font-medium mb-1'>Proveedor</label>
                  <Dropdown options={listProviders} className='p-inputtext-sm w-full' filter
                    placeholder='Selecciona un proveedor' emptyMessage='Sin registros'
                    name='provider' value={provider} onChange={onDropdownChange} />
                </div>
                <div className='md:mb-3'>
                  <label htmlFor='category' className='block font-medium mb-1'>Rubro</label>
                  <Dropdown options={listCategories} className='p-inputtext-sm w-full' filter
                    placeholder='Selecciona un rubro' emptyMessage='Sin registros'
                    name='category' value={category} onChange={onDropdownChange} />
                </div>
                <div className='mb-3'>
                  <label htmlFor='brand' className='block font-medium mb-1'>Marca</label>
                  <Dropdown options={listBrands} className='p-inputtext-sm w-full' filter
                    placeholder='Selecciona una marca' emptyMessage='Sin registros'
                    name='brand' value={brand} onChange={onDropdownChange} />
                </div>
              </div>
              <div className='grid grid-cols-3 gap-3'>
                <div className='md:mb-3 lg:mb-0'>
                  <label htmlFor='cashPrice' className='block font-medium mb-1'>Precio efectivo</label>
                  <InputNumber inputId='cashPrice' mode='currency' currency='ARS' locale='es-AR' className='w-full'
                    minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                    name='cashPrice' value={cashPrice} onValueChange={onInputChange} />
                </div>
                <div className='md:mb-3 lg:mb-0'>
                  <label htmlFor='debitPrice' className='block font-medium mb-1'>Precio débito</label>
                  <InputNumber inputId='debitPrice' mode='currency' currency='ARS' locale='es-AR' className='w-full'
                    minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                    name='debitPrice' value={debitPrice} onValueChange={onInputChange} />
                </div>
                <div className='md:mb-3 lg:mb-0'>
                  <label htmlFor='creditPrice' className='block font-medium mb-1'>Precio crédito</label>
                  <InputNumber inputId='creditPrice' mode='currency' currency='ARS' locale='es-AR' className='w-full'
                    minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                    name='creditPrice' value={creditPrice} onValueChange={onInputChange} />
                </div>
              </div>
            </div>
          </div>

          <div className='border-b border-[#1d64d8] mb-3'>
            <h3 className='text-[#1d64d8] text-lg font-medium pb-1'>Ficha Técnica</h3>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <SpecificationsComponent productId={id} onSpecificationsChange={handleSpecificationsChange} />
            </div>
          </div>

          <div className='border-b border-[#1d64d8] mb-3'>
            <h3 className='text-[#1d64d8] text-lg font-medium pb-1'>Información de compra (Próximamente)</h3>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <div className='flex items-center gap-3'>
                <div>
                  <label className='block font-medium mb-1'>Fecha de compra</label>
                  <Calendar dateFormat='dd/mm/yy' locale='es' placeholder='Seleccione una fecha'
                    name='purchaseDate' value={purchaseDate} onChange={handleDate} className='p-inputtext-sm' />
                </div>
                <div>
                  <label className='block font-medium mb-1'>Cantidad de ingreso</label>
                  <InputNumber className='p-inputtext-sm' name='purchaseQuantity' value={purchaseQuantity} onValueChange={onInputChange} />
                </div>
              </div>
            </div>
          </div>

          <div className='border-b border-[#1d64d8] mb-3'>
            <h3 className='text-[#1d64d8] text-lg font-medium pb-1'>Distribución</h3>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <DataTable value={listStocks} stripedRows size="small" emptyMessage="No se encontraron locales">
                <Column header='Sucursal' field='shop' className='font-medium' style={{ width: '45%' }} />
                <Column header='Dirección' field='direction' style={{ width: '45%' }} />
                <Column header='Cantidad' style={{ width: '10%' }}
                  body={(rowData) => (
                    <InputNumber inputClassName="p-inputtext-sm w-full"
                      value={rowData.quantity} onChange={(e) => handleQuantityChange(rowData, e.value)} />
                  )}>
                </Column>
              </DataTable>
            </div>
          </div>

          {errorMsg &&
            <div className='text-end mb-5'>
              <span className='text-red-500'>{errorMsg}</span>
            </div>
          }

          <div className='text-end'>
            <Button label='Registrar producto' onClick={handleCreateProduct} size='small'></Button>
          </div>
        </div>
      </div>
    </div >
  )
}