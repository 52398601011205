import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom"
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { ClipboardEdit } from 'lucide-react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import { usePagination } from '../../hooks/use.pagination'
import { formatCurrency, formatDateTime } from "../../helper/format"

import ProductFilters from '../../utils/filters.product'
import ProductService from '../../services/product.service'
import ExportProductsToExcel from '../../utils/export.products.component'

const productService = new ProductService()

export const ProductsPage = () => {

  const initialPagination = {
    page: 0,
    shop: 'TODOS',
    brand: 'TODOS',
    category: 'TODOS',
    provider: 'TODOS',
    orderByStock: null,
    recordsQuantity: 10
  }

  const orderStock = [
    { value: null, description: 'Sin orden' },
    { value: 'desc', description: 'Mayor a menor' },
    { value: 'asc', description: 'Menor a mayor' },
  ]

  const [first, setFirst] = useState(0)
  const [totalStock, setTotalStock] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [listProducts, setListProducts] = useState([])
  const [totalElements, setTotalElements] = useState(null)

  const { paginationState, onDropdownChange, setPaginationState } = usePagination(initialPagination)
  const { page, provider, category, brand, shop, orderByStock, recordsQuantity } = paginationState

  const { listShops, listProviders, listCategories, listBrands, listQuantities } = ProductFilters()

  useEffect(() => {
    searchValue === '' ? loadProducts() : searchByCriteria();
  }, [paginationState])

  const loadProducts = () => {
    productService.getAllWithQuantity(paginationState).then(data => {
      setListProducts(data.content)
      setTotalElements(data.totalElements)

      const totalStock = calculateTotalStock(data.content);
      setTotalStock(totalStock);
    })
  }

  const calculateTotalStock = (items) => {
    return items.reduce((total, item) => total + item.quantity, 0);
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el producto del sistema de manera permanente, esto no afectará a las ventas registradas.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3B82F6',
      cancelButtonColor: '#2493D8',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        productService.delete(id)
          .then((data) => {
            loadProducts()
            Swal.fire('Eliminado', '"' + data + '" ha sido eliminado del sistema con éxito.', 'success')
          })
          .catch((error) => {
            Swal.fire('Error', 'Hubo un problema al eliminar el producto.<br> Por favor, inténtelo de nuevo más tarde.', 'error')
          })
      }
    })
  }

  const searchByCriteria = () => {
    if (searchValue === '') {
      return
    }

    productService.getAllByCodeOrDescription({
      page: page,
      criteria: searchValue
    }).then((data) => {
      setListProducts(data.content)
      setTotalElements(data.totalElements)
    })
  }

  const header = <div className='flex items-center justify-between'>
    <div className='relative'>
      <InputText placeholder='Ingrese código o descripción del producto' className='p-inputtext-sm !pr-5 w-[280px]'
        value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
      <button onClick={searchByCriteria} className='absolute right-2 top-1.5'><i class="bi bi-search"></i></button>
    </div>
    <div>
      <p className='text-end text-sm font-medium'>Total de stock: {totalStock} unidades</p>
    </div>
  </div>

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Mis productos</h2>
        <span className='text-sm'>Vista general y control de todos los productos registrados en el sistema.</span>
      </div>

      <div className='panel'>
        <div className='panel-body grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-3'>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Local</label>
            <Dropdown value={shop} options={listShops} onChange={onDropdownChange}
              name='shop' className='p-inputtext-sm w-full' emptyMessage='Sin registros' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Proveedor</label>
            <Dropdown value={provider} options={listProviders} onChange={onDropdownChange}
              name='provider' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Rubro</label>
            <Dropdown value={category} options={listCategories} onChange={onDropdownChange}
              name='category' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Marca</label>
            <Dropdown value={brand} options={listBrands} onChange={onDropdownChange}
              name='brand' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Stock</label>
            <Dropdown value={orderByStock} options={orderStock} onChange={onDropdownChange}
              name='orderByStock' className='p-inputtext-sm w-full' optionLabel='description' placeholder='Selecciona un orden' />
          </div>
        </div>
      </div>

      <div className='flex items-center gap-3 mb-5'>
        <Link to={`/productos/modificacion/rapida`}>
          <div className='hover:cursor-pointer'>
            <span className='text-[#2a499b] text-sm'>Modificación rápida</span>
          </div>
        </Link>
        <span>|</span>
        <Link to={`/productos/modificacion/masiva`}>
          <div className='hover:cursor-pointer '>
            <span className='text-[#2a499b] text-sm'>Modificación masiva</span>
          </div>
        </Link>
      </div>

      <div className='panel'>
        <div className='panel-body'>
          <div className='flex flex-wrap items-center gap-3 mb-3'>
            <label className='block font-medium mb-1'>Cantidad</label>
            <Dropdown value={recordsQuantity} options={listQuantities} onChange={onDropdownChange}
              name='recordsQuantity' className='p-inputtext-sm' />

            <Link to={`/producto/registrar`}>
              <Button label='Agregar producto' icon='pi pi-plus-circle' severity='primary' />
            </Link>

            <ExportProductsToExcel products={listProducts} />
          </div>

          <DataTable value={listProducts} tableStyle={{ minWidth: '80rem' }} scrollable scrollHeight='440px' header={header}
            stripedRows emptyMessage='No se encontraron resultados' size='small'>
            <Column field='code' header='Código' style={{ width: '10%' }} />
            <Column field='description' header='Descripción' style={{ width: '40%' }} />
            <Column field={(rowData) => formatCurrency(rowData.cashPrice)} header='Efectivo' style={{ width: '10%' }} />
            <Column field={(rowData) => formatCurrency(rowData.debitPrice)} header='Débito' style={{ width: '10%' }} />
            <Column field={(rowData) => formatCurrency(rowData.creditPrice)} header='Crédito' style={{ width: '10%' }} />
            <Column field={(rowData) => rowData.lastPrice ? formatDateTime(rowData.lastPrice) : '-'} header='Ult. Precio' style={{ width: '10%' }} />
            <Column field='quantity' header='Stock' style={{ width: '5%' }} />
            <Column header='Acciones' style={{ width: 'auto' }}
              body={(rowData) => (
                <div className='flex gap-1'>
                  <Link to={`/producto/detalle/${rowData.id}`} target='_blank'>
                    <Button severity='primary' size='small'>
                      <i className='bi bi-eye-fill'></i>
                    </Button>
                  </Link>
                  <Link to={`/producto/modificar/${rowData.id}`} target='_blank'>
                    <Button severity='warning' size='small'>
                      <i className='bi bi-pencil-fill'></i>
                    </Button>
                  </Link>
                  <Button severity='danger' size='small'
                    onClick={() => handleDelete(rowData.id)} >
                    <i className='bi bi-trash-fill'></i>
                  </Button>
                </div>
              )}>
            </Column>
          </DataTable>
          <Paginator first={first} rows={recordsQuantity} totalRecords={totalElements}
            onPageChange={onPageChange} pageLinkSize={3} className='mt-3 !p-0' />
        </div>
      </div>
    </div>
  )
}