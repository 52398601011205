import React, { useEffect } from 'react'

import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { RadioButton } from 'primereact/radiobutton'
import { InputTextarea } from 'primereact/inputtextarea'

import { useForm } from '../../../hooks/use.form'

import ProductService from '../../../services/product.service'

const productService = new ProductService()

const SpecificationsComponent = ({ productId, onSpecificationsChange }) => {

  const initialSpecifications = {
    height: null, depth: null, width: null, length: null, capacity: null, weight: null,
    liters: null, wheels: null, colors: null, material: null, warranty: null,
    lights: 'No aplica', notebook: 'No aplica', organizer: 'No aplica', observations: null
  }

  const { formState, setFormState } = useForm(initialSpecifications)
  const { height, depth, width, length, capacity, weight, liters, wheels, colors,
    material, warranty, lights, notebook, organizer, observations } = formState

  useEffect(() => {
    if (productId) {
      productService.getById(productId)
        .then((data) => {
          if (data.specifications !== null) {
            setFormState(data.specifications)
          }
        })
    }
  }, [])

  useEffect(() => {
    calculateLengthAndLiters()
  }, [height, depth, width])

  const onChange = (e) => {
    const { name, value } = e.target
    setFormState({ ...formState, [name]: value })
  }

  const calculateLengthAndLiters = () => {
    const newLength = height + depth + width
    const newLiters = (height * depth * width) / 1000
    setFormState({
      ...formState,
      length: newLength ? newLength : null,
      liters: newLiters ? newLiters.toFixed(1) : null
    })
  }

  useEffect(() => {
    onSpecificationsChange(formState);
  }, [formState, onSpecificationsChange]);

  return (
    <div>
      <div className='grid grid-cols-3 md:grid-cols-4 gap-3 mb-3 md:mb-0'>
        <div className='md:mb-3'>
          <label className='block font-medium mb-1'>Altura</label>
          <InputNumber name='height' value={height} onValueChange={onChange}
            placeholder='0 CM' suffix=' CM' inputClassName='p-inputtext-sm w-full' />
        </div>
        <div className='md:mb-3'>
          <label className='block font-medium mb-1'>Profundidad</label>
          <InputNumber name='depth' value={depth} onValueChange={onChange}
            placeholder='0 CM' suffix=' CM' inputClassName='p-inputtext-sm w-full' />
        </div>
        <div className='md:mb-3'>
          <label className='block font-medium mb-1'>Ancho</label>
          <InputNumber name='width' value={width} onValueChange={onChange}
            placeholder='0 CM' suffix=' CM' inputClassName='p-inputtext-sm w-full' />
        </div>
        <div className='md:mb-3'>
          <label className='block font-medium mb-1'>Cm lineal</label>
          <InputNumber name='length' value={length} onValueChange={onChange}
            placeholder='0 CM' suffix=' CM' inputClassName='p-inputtext-sm w-full' />
        </div>
      </div>
      <div className='grid grid-cols-3 md:grid-cols-4 gap-3'>
        <div>
          <label className='block font-medium mb-1'>Capacidad</label>
          <InputNumber name='capacity' value={capacity} onValueChange={onChange}
            placeholder='0,0 kg' suffix=' kg' inputClassName='p-inputtext-sm w-full' minFractionDigits={1} />
        </div>
        <div>
          <label className='block font-medium mb-1'>Peso</label>
          <InputNumber name='weight' value={weight} onValueChange={onChange}
            placeholder='0,0 kg' suffix=' kg' inputClassName='p-inputtext-sm w-full' minFractionDigits={1} />
        </div>
        <div>
          <label className='block font-medium mb-1'>Litros</label>
          <InputNumber name='liters' value={liters} onValueChange={onChange}
            placeholder='0.0 L' suffix=' L' inputClassName='p-inputtext-sm w-full' minFractionDigits={1} />
        </div>
        <div>
          <label className='block font-medium mb-1'>Ruedas</label>
          <InputNumber name='wheels' value={wheels} onValueChange={onChange}
            placeholder='0' inputClassName='p-inputtext-sm w-full' />
        </div>
      </div>
      <hr className='my-3'></hr>
      <div className='md:flex justify-between gap-3'>
        <div className='w-full lg:w-1/2'>
          <div className='mb-3'>
            <label className='block font-medium mb-1'>Colores</label>
            <InputText name='colors' value={colors} onChange={onChange}
              placeholder='Ejemplo: rojo, azul' className='p-inputtext-sm w-full' />
          </div>
          <div className='mb-3'>
            <label className='block font-medium mb-1'>Material</label>
            <InputText name='material' value={material} onChange={onChange}
              placeholder='Ejemplo: cuero, poliéster' className='p-inputtext-sm w-full' />
          </div>
          <div className='mb-3'>
            <label className='block font-medium mb-1'>Garantía</label>
            <InputText name='warranty' value={warranty} onChange={onChange}
              placeholder="Ejemplo: 6 meses, 1 año" className='p-inputtext-sm w-full' />
          </div>
        </div>
        <div className='w-full lg:w-1/2 py-1 lg:pl-10'>
          <div className='mb-3'>
            <label className='block font-medium mb-1'>Luces</label>
            <div className='flex gap-3 py-1'>
              <div className='flex align-items-center'>
                <RadioButton inputId='lights1' name='lights' value='Si'
                  onChange={onChange} checked={lights === 'Si'} />
                <label htmlFor='lights1' className='ml-2'>Si</label>
              </div>
              <div className='flex align-items-center'>
                <RadioButton inputId='lights2' name='lights' value='No'
                  onChange={onChange} checked={lights === 'No'} />
                <label htmlFor='lights2' className='ml-2'>No</label>
              </div>
              <div className='flex align-items-center'>
                <RadioButton inputId='lights3' name='lights' value='No aplica'
                  onChange={onChange} checked={lights === 'No aplica'} />
                <label htmlFor='lights3' className='ml-2'>No aplica</label>
              </div>
            </div>
          </div>
          <div className='mb-3'>
            <label className='block font-medium mb-1'>Organizador</label>
            <div className='flex gap-3 py-1'>
              <div className='flex align-items-center'>
                <RadioButton inputId='organizer1' name='organizer' value='Si'
                  onChange={onChange} checked={organizer === 'Si'} />
                <label htmlFor='organizer1' className='ml-2'>Si</label>
              </div>
              <div className='flex align-items-center'>
                <RadioButton inputId='organizer2' name='organizer' value='No'
                  onChange={onChange} checked={organizer === 'No'} />
                <label htmlFor='organizer2' className='ml-2'>No</label>
              </div>
              <div className='flex align-items-center'>
                <RadioButton inputId='organizer3' name='organizer' value='No aplica'
                  onChange={onChange} checked={organizer === 'No aplica'} />
                <label htmlFor='organizer3' className='ml-2'>No aplica</label>
              </div>
            </div>
          </div>
          <div>
            <label className='block font-medium mb-1'>P. Notebook</label>
            <div className='flex gap-3 py-1'>
              <div className='flex items-center'>
                <RadioButton inputId='notebook1' name='notebook' value='Si'
                  onChange={onChange} checked={notebook === 'Si'} />
                <label htmlFor='notebook1' className='ml-2'>Si</label>
              </div>
              <div className='flex items-center'>
                <RadioButton inputId='notebook2' name='notebook' value='No'
                  onChange={onChange} checked={notebook === 'No'} />
                <label htmlFor='notebook2' className='ml-2'>No</label>
              </div>
              <div className='flex items-center'>
                <RadioButton inputId='notebook3' name='notebook' value='No aplica'
                  onChange={onChange} checked={notebook === 'No aplica'} />
                <label htmlFor='notebook3' className='ml-2'>No aplica</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='my-3'></hr>
      <div>
        <label className='block font-medium mb-1'>Observaciones</label>
        <InputTextarea name='observations' value={observations} onChange={onChange} rows={3}
          placeholder='Detalles adicionales del producto (opcional)' className='w-full' />
      </div>
    </div>
  )
}

export default SpecificationsComponent