import { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import UserContext from '../../user.context'

import { DateTime } from 'luxon'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'

import { useForm } from '../../hooks/use.form'

import ShopService from '../../services/shop.service'
import UserService from '../../services/user.service'
import ProductService from '../../services/product.service'
import RestockService from '../../services/restock.service'

const shopService = new ShopService()
const userService = new UserService()
const restockService = new RestockService()
const productService = new ProductService()

export const RestockCreatePage = () => {

  const initialForm = {
    type: '',
    code: '',
    origin: '',
    quantity: '',
    destination: '',
  }

  const [user] = useContext(UserContext)
  const [shops, setShops] = useState([])
  const [movements, setMovements] = useState([])
  const [listUsers, setListUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const { formState, setFormState, onInputChange, onDropdownChange } = useForm(initialForm)
  const { code, origin, destination, quantity, type } = formState

  const types = ['INGRESO', 'MOVIMIENTO']

  useEffect(() => {
    shopService.getAll().then(data => {
      const newList = data.map((shop) => shop.name)
      setShops(newList)
    })

    userService.getAll().then(data => {
      const currentUser = user.sub
      const filteredUsers = data.filter(u => u.username !== currentUser)
      setListUsers(filteredUsers)
    })
  }, [])

  const onCategoryChange = (e) => {
    let _selectedUsers = [...selectedUsers];

    if (e.checked)
      _selectedUsers.push(e.value);
    else
      _selectedUsers = _selectedUsers.filter(data => data.id !== e.value.id);

    setSelectedUsers(_selectedUsers);
  }

  const loadProduct = () => {
    if (!code || !origin || !destination || !quantity || !type) {
      Swal.fire('Error', 'Por favor, complete todos los campos.', 'error',)
      return
    }

    productService.getProductDescriptionByCode(code)
      .then(data => {
        const newProduct = {
          index: Date.now(),
          type: type,
          origin: origin,
          productCode: code,
          quantity: quantity,
          destination: destination,
          productDescription: data
        }

        setMovements(prevList => [...prevList, newProduct])
        setFormState(initialForm)
      })
      .catch(() => {
        Swal.fire('Producto no encontrado', 'No se encontró ningún producto que coincida con el código ingresado.', 'error',)
      })
  }

  const handleCreateRestock = () => {
    const newRestock = {
      author: user.sub,
      movements: movements,
      assignedUsers: selectedUsers.map(user => user.username)
    }

    restockService.create(newRestock)
      .then((data) => {
        setMovements([])
        Swal.fire('Registrado', 'Se ha registrado la reposición con éxito. <br> Número de solicitud: ' + data, 'success',)
      })
      .catch(() => {
        Swal.fire('Error', 'Ocurrió un error al intentar registrar la reposición.', 'error',)
      })
  }

  const handleDeleteProduct = (product) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el producto de la lista',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setMovements(prevList => prevList.filter((item) => item.index !== product.index))
        Swal.fire('Eliminado', 'Se elimino el producto de la lista.', 'success')
      }
    })
  }

  return (
    <div >
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Movimientos</h2>
        <span className='text-sm'>Generá un registro de los movimientos de tus productos y compartilo con el personal.</span>
      </div>

      <div className='md:flex gap-5'>
        <div className='md:w-1/3 lg:w-1/5 2xl:1/6'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Realiza</span>
            </div>

            <div className='panel-body'>
              <label>{user.sub}</label>
              <span className="block text-xs font-medium">{user.rol.split('_')[1]}</span>
            </div>
          </div>

          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Personal asignado</span>
            </div>

            <div className='panel-body grid grid-cols-1 gap-1 overflow-auto h-[578px] p-3'>
              {listUsers.map((data) => {
                return (
                  <div key={data.id} className="flex justify-between items-center pb-1 border-b">
                    <div>
                      <label htmlFor={data.id}>{data.username}</label>
                      <span className="block text-xs font-medium">{data.rol.split('_')[1]}</span>
                    </div>
                    <Checkbox inputId={data.id} value={data} onChange={onCategoryChange} checked={selectedUsers.some((item) => item.id === data.id)} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='md:w-2/3 lg:w-4/5 2xl:w-full'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Solicitud de reposición</span>
            </div>

            <div className='panel-body'>
              <div className='flex gap-3 mb-3'>
                <div className='w-full grid grid-cols-5 gap-3'>
                  <div>
                    <label className='block font-medium mb-2'>Código</label>
                    <InputText name='code' value={code} onChange={onInputChange}
                      className='p-inputtext-sm w-full' placeholder='Código de producto' />
                  </div>
                  <div>
                    <label className='block font-medium mb-2'>Origen</label>
                    <Dropdown name='origin' options={shops} value={origin} onChange={onDropdownChange}
                      className='p-inputtext-sm w-full' placeholder='Seleccione origen' />
                  </div>
                  <div>
                    <label className='block font-medium mb-2'>Destino</label>
                    <Dropdown name='destination' value={destination} options={shops} onChange={onDropdownChange}
                      className='p-inputtext-sm w-full' placeholder='Seleccione destino' />
                  </div>
                  <div>
                    <label className='block font-medium mb-2'>Cantidad</label>
                    <InputNumber name='quantity' value={quantity} onValueChange={onInputChange}
                      className='w-full p-inputtext-sm' placeholder='Cantidad' />
                  </div>
                  <div>
                    <label className='block font-medium mb-2'>Tipo</label>
                    <Dropdown name='type' value={type} options={types} onChange={onDropdownChange}
                      className='p-inputtext-sm w-full' placeholder='Seleccione tipo' />
                  </div>
                </div>
                <div>
                  <label className='invisible block font-medium mb-2'>Tipo</label>
                  <Button label='Agregar' onClick={loadProduct} size='small' className='!rounded' />
                </div>
              </div>

              <DataTable value={movements} tableStyle={{ minWidth: '50rem' }} style={{ minHeight: '551px' }}
                scrollHeight="551px" emptyMessage='Sin registros' size='small' scrollable stripedRows >
                <Column field='productCode' header='Codigo' className='rounded-tl' style={{ width: '10%' }} />
                <Column field='productDescription' header='Producto' style={{ width: '50%' }} />
                <Column field='type' header='Tipo' style={{ width: '10%' }} />
                <Column field='origin' header='Origen' style={{ width: '10%' }} />
                <Column field='destination' header='Destino' style={{ width: '10%' }} />
                <Column field='quantity' header='Cantidad' style={{ width: '10%' }} />
                <Column className='rounded-tr'
                  body={(rowData) => (
                    <div className='flex justify-center'>
                      <Button className='!rounded' severity='danger' size='small'
                        onClick={() => handleDeleteProduct(rowData)} >
                        <i className='bi bi-trash-fill'></i>
                      </Button>
                    </div>
                  )}>
                </Column>
              </DataTable>
            </div>

            <div className='flex justify-between border-b border-x items-center bg-gray-100 p-3'>
              <p className='font-medium'>Total de unidades:
                <span> {movements.reduce((total, product) => total + product.quantity, 0)}</span>
              </p>

              <Button label='Registrar' onClick={handleCreateRestock} size='small' className='!rounded' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
