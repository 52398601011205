import { useEffect, useState } from 'react'

import { Tag } from 'primereact/tag'

import { formatDateTime } from '../../helper/format'

import RestockService from '../../services/restock.service'
import { Link, useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'

const restockService = new RestockService()

export const RestockCheckPage = () => {

  const { id } = useParams()
  const [restock, setRestock] = useState([])
  const [movements, setMovements] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])

  const checkOptions = ['FALTA', 'CORRECTO', 'EXCEDE']

  useEffect(() => {
    restockService.getById(id).then(data => {
      setRestock(data)
      setMovements(data.movements)
      setAssignedUsers(data.assignedUsers)
      console.log(data)
    })
  }, [])

  return (
    <div>
      <h2 className='text-2xl mb-1'>Solicitud de reposición N°{restock.id}</h2>

      <div>
        <span className='font-medium block mb-2'>Realiza: {restock.author}</span>
        <span className='font-medium block'>Estado:&nbsp;
          {restock.state === 'CANCELADO' && <Tag severity='danger' value='Cancelado' className='!rounded' />}
          {restock.state === 'PENDIENTE' && <Tag severity='warning' value='Pendiente' className='!rounded' />}
          {restock.state === 'COMPLETADO' && <Tag severity='success' value='Completado' className='!rounded' />}
        </span>
      </div>

      <div className='mt-5'>
        <div className='panel'>
          <div className='panel-heading'>
            <span className='font-medium'>Total de unidades: {restock.quantity}</span>
            <span>{formatDateTime(restock.dateTime)}</span>
          </div>

          <div className='panel-body'>
            <DataTable value={movements} stripedRows emptyMessage='No se encontraron unidades' size='small'>
              <Column field='productCode' header='Codigo' style={{ width: '10%' }} />
              <Column field='productDescription' header='Producto' style={{ width: '40%' }} />
              <Column field='type' header='Tipo' style={{ width: '10%' }} />
              <Column field='origin' header='Origen' style={{ width: '10%' }} />
              <Column field='destination' header='Destino' style={{ width: '10%' }} />
              <Column field='quantity' header='Cantidad' style={{ width: '10%' }} />

              {assignedUsers.map((data) =>
                <Column header={data} style={{ width: 'auto' }}
                  body={
                    <Dropdown name='check' options={checkOptions} value={''} onChange={''}
                      className='p-inputtext-sm w-full' placeholder='Seleccione una opción' />
                  }
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>
    </div >
  )
}