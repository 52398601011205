import axios from "axios"

import { builderParams } from "../helper/builder.params"
import { BASE_URL } from "../helper/axion.helper"

const baseUrl = BASE_URL + "/api/v1/product"

class ProductService {

    getById(id) { return axios.get(baseUrl + "/" + id).then(res => res.data) }

    getByCode(code) { return axios.get(baseUrl + "/getProductByCode?code=" + code).then(res => res.data) }

    getProductIdByCode(code) { return axios.get(baseUrl + "/getProductIdByCode?code=" + code).then(res => res.data) }

    getProductDescriptionByCode(code) { return axios.get(baseUrl + "/getProductDescriptionByCode?code=" + code).then(res => res.data) }

    create(product, stocks) { return axios.post(baseUrl + "/createProduct", product, stocks).then(res => res.data) }

    update(product) { return axios.put(baseUrl + "/updateProduct", product).then(res => res.data) }

    delete(id) { return axios.delete(baseUrl + "/deleteProduct/" + id).then(res => res.data) }

    updatePrices(request) { return axios.put(baseUrl + "/updatePrices", request).then(res => res.data) }

    updateProducts(products) { return axios.put(baseUrl + "/updateProducts", products).then(res => res.data) }

    getByCodeOrBarcode(request) { 
        const params = builderParams(request)
        return axios.get(baseUrl + "/code?" + params).then(res => res.data) 
    }

    getAll(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/getAllProducts?" + params).then(res => res.data)
    }

    getAllWithStock(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/stock?" + params).then(res => res.data)
    }

    getAllByCriteria(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/getAllProductsByCriteria?" + params).then(res => res.data)
    }

    getAllByCodeOrDescription(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/getAllByCodeOrDescription?" + params).then(res => res.data)
    }

    getAllWithQuantity(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/getAllProductsWithQuantity?" + params).then(res => res.data)
    }

}

export default ProductService;