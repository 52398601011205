import { useEffect, useState } from 'react'

import { Paginator } from 'primereact/paginator'
import { formatCurrency } from '../helper/format'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'

import ProductService from '../services/product.service'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const productService = new ProductService()

export const SearchPage = () => {

  const navigate = useNavigate()

  const { criteria } = useParams()
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const page = query.get('page') ? parseInt(query.get('page'), 10) : 0;

  const [rows, setRows] = useState(10)
  const [first, setFirst] = useState(0)
  const [listProducts, setListProducts] = useState([])
  const [totalElements, setTotalElements] = useState(null)
  const [paginationState, setPaginationState] = useState({
    criteria: criteria, page: page
  })

  useEffect(() => {
    setPaginationState({ criteria: criteria, page: page })
  }, [criteria, page])

  useEffect(() => {
    productService.getAllByCriteria(paginationState).then((data) => {
      if (data.content.length === 1) {
        const productId = data.content[0].id
        navigate(`/producto/detalle/${productId}`)
      } else {
        setListProducts(data.content)
        setTotalElements(data.totalElements)
      }
    })
  }, [paginationState])

  const onPageChange = (event) => {
    setFirst(event.first)
    setRows(event.rows)
    const request = { ...paginationState, page: event.page }
    productService.getAllByCriteria(request).then(data => {
      setListProducts(data.content)
    })

    // Update URL parameters
    navigate(`${location.pathname}?page=${event.page}`, { replace: true });

    setPaginationState(request);
  }

  return (
    <div className='xl:container xl:m-auto xl:px-10 2xl:px-40'>
      <div className='pb-[15px]'>
        <h2 className='text-2xl'>Resultados de busqueda: "{criteria}"</h2>
      </div>

      <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        {listProducts.length > 0
          ? listProducts.map((product) => (
            <div key={product.code} className='bg-white xl:h-[514px] xl:w-[224px] rounded-md border cursor-pointer hover:shadow-2xl'>
              <div className='flex sm:block'>
                <div className='w-2/5 sm:w-full border-b'>
                  <LazyLoadImage src={product.image ? `${product.image}` : '/producto-sin-foto.jpg'}
                    className='h-full xl:h-[244px] xl:w-[224px] object-cover' alt='product' />
                </div>
                <div className='w-3/5 flex flex-col justify-between md:h-[270px] sm:w-full p-5'>
                  <div>
                    <span className='text-sm text-gray-400'>Código: {product.code}</span>
                    <h3 className='font-medium text-md text-gray-600'>{product.description.length > 56 ?
                      product.description.substring(0, 56) + '...' : product.description}</h3>
                    <div className='my-3'>
                      <div className='flex justify-between text-sm'>
                        <p className='font-medium text-gray-500'>Efectivo</p>
                        <p className='mt-1'>{formatCurrency(product.cashPrice)}</p>
                      </div>
                      <div className='flex justify-between text-sm'>
                        <p className='font-medium text-gray-500'>Débito</p>
                        <p className='mt-1'>{formatCurrency(product.debitPrice)}</p>
                      </div>
                      <div className='flex justify-between text-sm'>
                        <p className='font-medium text-gray-500'>Crédito</p>
                        <p className='mt-1'>{formatCurrency(product.creditPrice)}</p>
                      </div>
                    </div>
                  </div>
                  <Link to={`/producto/detalle/${product.id}`} target='_blank'>
                    <div className='bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 text-center rounded-md p-2'>
                      <p className='text-white'>Ver producto</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))
          : <div>No se encontraron resultados</div>
        }
      </div>
      <Paginator first={first} rows={rows} pageLinkSize={3} totalRecords={totalElements}
        onPageChange={onPageChange} className='!bg-transparent !p-0 mt-5'></Paginator>
    </div >
  )
}