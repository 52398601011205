import { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import UserContext from '../../user.context'

import Sidebar, { SidebarItem } from '../components/sidebar.component'
import { Activity, Bell, Clipboard, ClipboardList, HomeIcon, Info, LayoutDashboardIcon, MessageSquare, Package, ShoppingCart, User } from 'lucide-react'

const Menu = () => {

  const location = useLocation()
  const [user] = useContext(UserContext)

  return (
    <Sidebar>
      <NavLink className='text-decoration-none' to={'/inicio'}>
        <SidebarItem icon={<HomeIcon className='m-auto' size={24} />} text='Inicio' />
      </NavLink>

      {user.rol === 'ROLE_ADMINISTRADOR' && (
        <div>
          <NavLink className='text-decoration-none' to={'/panel'}>
            <SidebarItem icon={<LayoutDashboardIcon className='m-auto' size={24} />} text='Panel' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/personalizados'}>
            <SidebarItem icon={<ClipboardList className='m-auto' size={24} />} text='Listas' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/venta/historial'}>
            <SidebarItem icon={<ShoppingCart className='m-auto' size={24} />} text='Ventas' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/clientes'}>
            <SidebarItem icon={<User className='m-auto' size={24} />} text='Clientes' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/productos'}>
            <SidebarItem icon={<Package className='m-auto' size={24} />} text='Productos' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/reposiciones'}>
            <SidebarItem icon={<Clipboard className='m-auto' size={24} />} text='Reposicion' />
          </NavLink>
          <NavLink className='text-decoration-none' to={'/movimientos'}>
            <SidebarItem icon={<Activity className='m-auto' size={24} />} text='Movimientos' />
          </NavLink>
        </div>
      )}

      <NavLink className='text-decoration-none' to={'/mensajeria'}>
        <SidebarItem icon={<MessageSquare className='m-auto' size={24} />} text='Mensajería' />
      </NavLink>
      <NavLink className='text-decoration-none' to={'/notificaciones'}>
        <SidebarItem icon={<Bell className='m-auto' size={24} />} text='Notificaciones' />
      </NavLink>

      {user.rol === 'ROLE_ADMINISTRADOR' && (
        <NavLink className='text-decoration-none' to={'/novedades'}>
          <SidebarItem icon={<Info className='m-auto' size={24} />} text='Novedades' />
        </NavLink>
      )}
    </Sidebar>
  )
}

export default Menu;