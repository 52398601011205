export default function Sidebar({ children }) {
  return (
    <aside className="bg-[#2A499B] hidden 2xl:block">
      <div className='min-h-screen'>
        <nav className="flex flex-col">
          <ul className="flex-1 mt-2 px-2">{children}</ul>
        </nav>
      </div>
    </aside>
  )
}

export function SidebarItem({ icon, text }) {
  return (
    <li className='text-center font-medium cursor-pointer transition-colors group py-2 my-1 
    hover:underline underline-offset-4 text-white'>
      <span>{icon}</span>
      <p className="text-[11px]">{text}</p>
    </li>
  )
}