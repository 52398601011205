import axios from "axios";

import { builderParams } from "../helper/builder.params"
import { BASE_URL } from "../helper/axion.helper"

const baseUrl = BASE_URL + "/api/v1/stock";

class StockService {

    delete(id) { return axios.delete(baseUrl + "/" + id).then(res => res.data) }

    getAllByShop(name, request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/getAllByShop/" + name + "?" + params).then(res => res.data)
    }

    getAllByProductId(id) { return axios.get(baseUrl + "/getAllStocksByProduct/" + id).then(res => res.data) }

    async getTotalStockByProduct(id) {
        const res = await axios.get(baseUrl + "/getTotalStockByProduct/" + id);
        return res.data;
    }

    getByProductAndShop(product, shop) {
        return axios.get(baseUrl + "/getByProductAndShop?product=" + product + "&shop=" + shop).then(res => res.data)
    }

}

export default StockService;
