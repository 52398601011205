import { useContext } from 'react'
import { AppRouter } from '../router/AppRouter'

import Menu from '../shared/components/menu.component'
import Header from '../shared/components/header.component'

import UserContext from '../user.context'

export const HomePage = () => {

  const [user] = useContext(UserContext)

  return (
    <div className='flex'>
      {user.rol !== 'ROLE_BUSCADOR' && <Menu></Menu>}
      <div className='bg-[#F9F9FB] w-full'>
        <Header></Header>
        <div className='overflow-y-auto h-screen px-5 pt-5 pb-20'>
          <AppRouter></AppRouter>
        </div>
      </div>
    </div>
  )
}
