import { useState, useRef } from 'react';

export const useImageUtils = () => {

  const [base64Image, setBase64Image] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [imgPreviewSrc, setImgPreviewSrc] = useState(null)

  // Display selected image
  const handleImageChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      let reader = new FileReader()

      reader.onload = (event) => {
        setSelectedImage(file)
        setImgPreviewSrc(event.target.result)
        setBase64Image(event.target.result.split(',')[1])
      }

      reader.readAsDataURL(file)
    }
  }

  return { base64Image, selectedImage, imgPreviewSrc, setBase64Image, setImgPreviewSrc, handleImageChange };
};