import { useState } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { addLocale } from 'primereact/api'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable'
import { usePagination } from '../../hooks/use.pagination'
import { useCalculateTotal } from '../../hooks/venta.calcular'
import { regionalCalendar } from '../../helper/regional.calendar'
import { formatCurrency, formatDate, formatTime } from '../../helper/format'

import SaleFilters from '../../utils/filters.sale'
import SaleService from '../../services/sale.service'

const saleService = new SaleService()

export const SaleHistoryByCategoryPage = () => {

  addLocale('es', regionalCalendar)

  const initialPagination = {
    page: 0,
    shop: 'TODOS',
    category: null,
    dateFrom: null,
    dateUntil: null,
    recordsQuantity: 'TODOS'
  }

  const [loading, setLoading] = useState(false)
  const [listItems, setListItems] = useState([])
  const [soldQuantity, setSoldQuantity] = useState(0)

  const { shops, categories } = SaleFilters()
  const { totalCash, totalDebit, totalCredit, totalCodeQr, totalFinal } = useCalculateTotal(listItems)

  const { paginationState, onDropdownChange, handleDate } = usePagination(initialPagination)
  const { dateFrom, dateUntil, shop, category } = paginationState

  let networkTimeout = null;

  const filter = () => {
    if (dateFrom === null) {
      Swal.fire('Error', 'Debes seleccionar una fecha de inicio', 'error')
      return
    }

    if (dateUntil === null) {
      Swal.fire('Error', 'Debes seleccionar una fecha de fin', 'error')
      return
    }

    if (category === null) {
      Swal.fire('Error', 'Debes seleccionar un rubro', 'error')
      return
    }

    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    networkTimeout = setTimeout(() => {
      saleService.getAllByCategory(paginationState).then(data => {
        setLoading(false)
        setSoldQuantity(data.soldQuantity)
        setListItems(data.listItems.content)
      });
    }, Math.random() * 1000 + 250)
  }

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Historial de ventas por rubro</h2>
        <span className='text-sm'>Visualización histórica de ventas segmentadas por rubro.</span>
      </div>

      <div className='md:flex gap-5'>
        <div className='md:w-1/3 lg:w-1/4 2xl:w-1/6'>
          <div className='panel'>
            <div className='panel-body grid grid-cols-2 md:grid-cols-1 gap-3'>
              <div>
                <label className='block font-medium mb-1'>Fecha desde</label>
                <Calendar dateFormat='dd/mm/yy' locale='es' placeholder='Seleccione fecha desde'
                  name='dateFrom' onChange={handleDate} className='p-inputtext-sm w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Fecha hasta</label>
                <Calendar dateFormat='dd/mm/yy' locale='es' placeholder='Seleccione fecha hasta'
                  name='dateUntil' onChange={handleDate} className='p-inputtext-sm w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Local</label>
                <Dropdown options={shops} emptyMessage='Sin registros' placeholder='Selecciona un local'
                  name='shop' value={shop} onChange={onDropdownChange} className='p-inputtext-sm w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Rubro</label>
                <Dropdown options={categories} emptyMessage='Sin registros' placeholder='Selecciona un rubro'
                  name='category' value={category} onChange={onDropdownChange} className='p-inputtext-sm w-full' filter />
              </div>

              <Button label="Buscar" onClick={filter} className='w-full' size='small' />
            </div>
          </div>

          <div className='panel'>
            <div className='panel-body flex justify-between'>
              <p className='text-lg text-blue-500 font-medium'>Total vendidos</p>
              <span className='text-xl'>{soldQuantity}</span>
            </div>
          </div>

          <div className='panel !bg-[#348FE2]'>
            <div className='panel-body'>
              <div className='!text-white'>
                <div className='flex justify-between'>
                  <p>Efectivo</p>
                  <span>{totalCash()}</span>
                </div>
                <div className='flex justify-between'>
                  <p>Débito</p>
                  <span>{totalDebit()}</span>
                </div>
                <div className='flex justify-between'>
                  <p>Código QR</p>
                  <span>{totalCodeQr()}</span>
                </div>
                <div className='flex justify-between'>
                  <p>Crédito</p>
                  <span>{totalCredit()}</span>
                </div>
              </div>
            </div>
            <div className='p-[15px] rounded-b-lg' style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <div className='!text-white flex justify-between'>
                <p className='font-medium'>Total ventas</p>
                <span>{listItems.length !== 0 ? totalFinal() : '$ 0'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='md:w-2/3 lg:w-3/4 2xl:w-5/6'>
          <div className='panel'>
            <div className='panel-body'>
              <DataTable value={listItems} loading={loading} tableStyle={{ minWidth: '70rem' }} style={{ maxHeight: '700px' }} scrollHeight="700px"
                scrollable stripedRows emptyMessage='Sin registro de ventas' size='small'>
                <Column field='id' header='Código' className='font-medium' style={{ width: '10%' }} />
                <Column field='shop' header='Local' style={{ width: '10%' }} />
                <Column field='user' header='Usuario' style={{ width: '10%' }} />
                <Column field={(rowData) => formatDate(rowData.date)} header='Fecha' style={{ width: '10%' }} />
                <Column field={(rowData) => formatTime(rowData.time)} header='Hora' style={{ width: '10%' }} />
                <Column header='Producto/s' style={{ width: '40%' }}
                  body={(rowData) => (
                    <div>
                      {rowData.details.map((product, index) => (
                        <p className="m-auto" key={index}>
                          <span>{product.description}</span>
                        </p>
                      ))}
                    </div>
                  )}>
                </Column>
                <Column field={(rowData) => formatCurrency(rowData.total)} header='Total' className='font-medium' style={{ width: '10%' }}></Column>
                <Column className='font-medium' style={{ width: 'auto' }}
                  body={(rowData) => (
                    <div className='flex justify-center'>
                      <Link to={`/venta/detalle/${rowData.id}`} target='_blank'>
                        <Button className='!rounded' severity='primary' size='small'>
                          <i className='bi bi-eye-fill'></i>
                        </Button>
                      </Link>
                    </div>
                  )}>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}