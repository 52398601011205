import axios from "axios";

import { BASE_URL, axiosInstance } from "../helper/axion.helper"
import { builderParams } from "../helper/builder.params"

class NotificationService {

  baseUrl = BASE_URL + "/api/v1/notification";

  getAllByUserAndShop(request) {
    const params = builderParams(request)
    return axiosInstance.get(this.baseUrl + "/getAllNotifications?" + params).then(res => res.data) 
  }

  getAllReadByUserAndShop(request) {
    const params = builderParams(request)
    return axiosInstance.get(this.baseUrl + "/getAllReadNotifications?" + params).then(res => res.data) 
  }

  delete(id) {return axiosInstance.delete(this.baseUrl + "/deleteNotification/" + id).then(res => res.data) }

  markAsRead(id) {return axiosInstance.put(this.baseUrl + "/markNotificationAsRead/" + id).then(res => res.data) }
  
  deleteSelected(notifications) {return axiosInstance.put(this.baseUrl + "/deleteNotifications", notifications).then(res => res.data) }

  markSelectedAsRead(notifications) {return axiosInstance.put(this.baseUrl + "/markNotificationsAsRead", notifications).then(res => res.data) }

}

export default NotificationService;