import { useContext, useEffect, useState } from 'react'
import UserContext from '../../user.context'

import { Card } from 'primereact/card'
import { Copy, Edit } from 'lucide-react'
import { Column } from 'primereact/column'
import { Link, useParams } from 'react-router-dom'
import { TabMenu } from 'primereact/tabmenu'
import { DataTable } from 'primereact/datatable'

import { formatCurrency, formatDateTime } from '../../helper/format'

import StockService from '../../services/stock.service'
import ProductService from '../../services/product.service'
import UserService from '../../services/user.service'

const userService = new UserService()
const stockService = new StockService()
const productService = new ProductService()

export const ProductDetailPage = () => {

  const { id } = useParams()
  const [user] = useContext(UserContext)

  const items = [
    { label: 'Stock' },
    { label: 'Ficha técnica' },
    { label: 'Código de barras' },
  ]

  const [shop, setShop] = useState([])
  const [stocks, setStocks] = useState([])
  const [product, setProduct] = useState([])
  const [specifications, setSpecifications] = useState([])

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    loadProduct()

    userService.getByName(user.sub).then(data => {
      setShop(data.shop)
    })
  }, []);

  const loadProduct = () => {
    productService.getById(id).then(data => {
      setProduct(data)
      setSpecifications(data.specifications)
    })

    stockService.getAllByProductId(id).then(data => {
      setStocks(data)
    })
  }

  const generateTableRow = (label, value) => {
    return value !== null ? (
      <tr key={label} className='border-b'>
        <th className='text-left p-2' scope="row">{label}</th>
        <td className='text-end p-2'>{value}</td>
      </tr>
    ) : null;
  }

  return (
    <div className='container mx-auto lg:px-28 xl:px-48 2xl:px-72'>
      <div className='bg-[#2A499B] text-center rounded mb-[15px] p-3'>
        <h1 className='text-2xl !text-white'>Código: {product.code}</h1>
      </div>

      <div className='md:flex justify-between gap-5'>
        <div className='lg:w-1/2 mb-5'>
          <img src={product.base64Image || '/producto-sin-foto.jpg'} alt='product'
            className='shadow-none border rounded-lg w-full min-h-full' />
        </div>
        <div className='lg:w-1/2'>
          <div className='panel'>
            <div className='panel-body'>
              <h2 className='text-xl'>{product.description}</h2>
            </div>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <table className='text-sm w-full mb-3'>
                <thead>
                  <tr className='bg-gray-200'>
                    <th className='text-start rounded-t-md p-2' colSpan={2}>
                      <p className='text-black font-medium'>Características generales</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user.rol === 'ROLE_ADMINISTRADOR' &&
                    <tr className='border-b'>
                      <td className='p-2'><p className='font-medium'>Proveedor</p></td>
                      <td><p className='text-end'>{product.provider || 'Sin proveedor'}</p></td>
                    </tr>
                  }
                  <tr className='border-b'>
                    <td className='p-2'><p className='font-medium'>Rubro</p></td>
                    <td><p className='text-end'>{product.category || 'Sin rubro'}</p></td>
                  </tr>
                  <tr className='border-b'>
                    <td className='p-2'><p className='font-medium'>Marca</p></td>
                    <td><p className='text-end'>{product.brand || 'Sin marca'}</p></td>
                  </tr>
                  <tr className='border-b'>
                    <td className='p-2'><p className='font-medium'>Efectivo</p></td>
                    <td><p className='text-end'>{formatCurrency(product.cashPrice)}</p></td>
                  </tr>
                  <tr className='border-b'>
                    <td className='p-2'><p className='font-medium'>Débito</p></td>
                    <td><p className='text-end'>{formatCurrency(product.debitPrice)}</p></td>
                  </tr>
                  <tr className='border-b'>
                    <td className='p-2'><p className='font-medium'>Crédito</p></td>
                    <td><p className='text-end'>{formatCurrency(product.creditPrice)}</p></td>
                  </tr>
                </tbody>
              </table>

              <p className='text-[#2A499B] text-end'>Última actualización de precios:
                <span> {product.lastPrice ? formatDateTime(product.lastPrice) : 'Sin registros'}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='panel'>
        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='border-x border-t' />
        {activeIndex === 0 &&
          <div className='panel-body'>
            <DataTable value={stocks} stripedRows emptyMessage='No se encontraron unidades' size='small'>
              <Column field={(rowData) => (rowData.shop) + " - " + (rowData.direction)}
                header='Sucursal' style={{ width: '40%' }} />
              <Column header='Cantidad' style={{ width: '40%' }}
                body={(rowData) => {
                  if (user.rol === 'ROLE_ADMINISTRADOR') {
                    return (<p>{`${rowData.quantity} unidad${rowData.quantity !== 1 ? 'es' : ''}`}</p>)
                  }

                  if (user.rol === 'ROLE_USUARIO' && rowData.shop === shop && rowData.quantity > 0) {
                    return (<p className='font-medium'>{`${rowData.quantity} unidad${rowData.quantity !== 1 ? 'es' : ''}`}</p>)
                  }

                  if (rowData.quantity >= 2) {
                    return (<p className='font-medium text-blue-500'>En stock +</p>)
                  }

                  if (rowData.quantity === 1) {
                    return (<p className='font-medium text-green-500'>En stock</p>)
                  }

                  if (rowData.quantity <= 0) {
                    return (<p className='font-medium text-red-500'>Sin stock</p>)
                  }
                }}>
              </Column>
              <Column field={(rowData) => formatDateTime(rowData.lastUpdate)}
                header='Ult. Act' style={{ width: '20%' }} />
            </DataTable>
          </div>
        }
        {activeIndex === 1 &&
          <div className='panel-body'>
            {product.specifications ?
              <table className='table text-sm w-full'>
                <tbody>
                  {[
                    ['Altura', specifications.height !== null ? specifications.height + ' cm' : null],
                    ['Profundidad', specifications.depth !== null ? + specifications.depth + ' cm' : null],
                    ['Ancho', specifications.width !== null ? specifications.width + ' cm' : null],
                    ['Cm lineal', specifications.length !== null ? specifications.length + ' cm' : null],
                    ['Capacidad', specifications.capacity !== null ? specifications.capacity + ' kg' : null],
                    ['Peso', specifications.weight !== null ? specifications.weight + ' kg' : null],
                    ['Litros', specifications.liters !== null ? specifications.liters + ' L' : null],
                    ['Ruedas', specifications.wheels],
                    ['Colores', specifications.colors],
                    ['Material', specifications.material],
                    ['Garantía', specifications.warranty],
                    ['Luces', specifications.lights],
                    ['Organizador', specifications.organizer],
                    ['P. Notebook', specifications.notebook],
                    ['Observaciones', specifications.observations],
                  ].map(([label, value]) => {
                    if (value !== null) {
                      return generateTableRow(label, value);
                    }
                    return null
                  })}
                </tbody>
              </table>
              :
              <span>Este producto no posee una ficha técnica registrada</span>
            }
          </div>
        }
        {activeIndex === 2 &&
          <div className='panel-body'>
            {product.barcodeNumber ?
              <img src={product.base64barcode} alt='barcode' className='m-auto border rounded'></img>
              :
              <span>Este producto no posee código de barras</span>
            }
          </div>
        }
      </div>

      {user.rol === 'ROLE_ADMINISTRADOR' &&
        <div className='flex justify-end gap-5 mb-3'>
          <Link to={`/producto/modificar/${product.id}`}>
            <div className='border rounded text-center hover:cursor-pointer hover:bg-blue-100 w-[183px] h-[150px] p-[24px]'>
              <Edit className='text-blue-500 mx-auto mb-[14px]' size={55} />
              <span className='text-sm font-medium hover:text-[#2A499B]'>EDITAR</span>
            </div>
          </Link>

          <Link to={`/producto/registrar/${product.id}`}>
            <div className='border rounded text-center hover:cursor-pointer hover:bg-blue-100 w-[183px] h-[150px] p-[24px]'>
              <Copy className='text-blue-500 mx-auto mb-[14px]' size={55} />
              <span className='text-sm font-medium hover:text-[#2A499B]'>CREAR SIMILAR</span>
            </div>
          </Link>
        </div>
      }
    </div >
  )
}