import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { addLocale } from 'primereact/api'
import { ClipboardList } from 'lucide-react'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'

import { usePagination } from '../../hooks/use.pagination'
import { useCalculateTotal } from '../../hooks/venta.calcular'
import { regionalCalendar } from '../../helper/regional.calendar'
import { formatCurrency, formatDate, formatTime } from '../../helper/format'

import SalesFilters from '../../utils/filters.sale'
import SaleService from '../../services/sale.service'
import ExportSalesToExcel from '../../utils/export.sales.component'
import { Button } from 'primereact/button'

const saleService = new SaleService()

export const SaleHistoryPage = () => {

  const initialPagination = {
    page: 0,
    shop: 'TODOS',
    payment: 'TODOS',
    dateFrom: null,
    dateUntil: null,
    recordsQuantity: 10
  }

  const [first, setFirst] = useState(0)
  const [listItems, setListItems] = useState([])
  const [totalElements, setTotalElements] = useState(null)

  const { shops, payments, quantities } = SalesFilters()
  const { totalCash, totalDebit, totalCredit, totalCodeQr, totalFinal } = useCalculateTotal(listItems)
  const { paginationState, onDropdownChange, handleDate, setPaginationState } = usePagination(initialPagination)

  const { shop, payment, recordsQuantity } = paginationState

  useEffect(() => {
    loadSales()
  }, [paginationState])

  const loadSales = () => {
    saleService.getAll(paginationState).then(data => {
      setListItems(data.content)
      setTotalElements(data.totalElements)
    })
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el registro de venta del sistema y los productos seran reintregrados al stock del local',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        saleService.delete(id)
          .then((data) => {
            loadSales()
            Swal.fire('Eliminado', 'La venta #' + id + ' ha sido eliminada con éxito.', 'success')
          })
          .catch((error) => {
            Swal.fire('Error', 'Hubo un problema al intentar eliminar la venta #' + id + '.<br> Por favor, inténtelo de nuevo más tarde.', 'error')
          });
      }
    })
  }

  addLocale('es', regionalCalendar)

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Historial de ventas</h2>
        <span className='text-sm'>Gestioná y explorá el registro histórico de ventas en todas las sucursales.</span>
      </div>

      <div className='panel'>
        <div className='panel-body grid grid-cols-2 md:grid-cols-4 gap-3'>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Desde</label>
            <Calendar dateFormat='dd/mm/yy' locale='es' placeholder='Seleccione una fecha'
              name='dateFrom' onChange={handleDate} className='p-inputtext-sm w-full' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Hasta</label>
            <Calendar dateFormat='dd/mm/yy' locale='es' placeholder='Seleccione una fecha'
              name='dateUntil' onChange={handleDate} className='p-inputtext-sm w-full' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Local</label>
            <Dropdown options={shops} emptyMessage='Sin registros'
              name='shop' value={shop} onChange={onDropdownChange} className='p-inputtext-sm w-full' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Pago</label>
            <Dropdown options={payments} emptyMessage='Sin registros'
              name='payment' value={payment} onChange={onDropdownChange} className='p-inputtext-sm w-full' />
          </div>
        </div>
      </div>

      <div className='flex items-center gap-3 mb-5'>
        <Link to={`/venta/historial/rubro`}>
          <div className='hover:cursor-pointer'>
            <span className='text-[#2a499b] text-sm'>Historial por rubro</span>
          </div>
        </Link>
        <span>|</span>
        <Link to={`/venta/historial/producto`}>
          <div className='hover:cursor-pointer '>
            <span className='text-[#2a499b] text-sm'>Historial por producto</span>
          </div>
        </Link>
      </div>

      <div className='panel'>
        <div className='panel-body'>
          <div className='flex items-center gap-3 mb-3'>
            <label className='block font-medium mb-2 lg:mb-0'>Cantidad</label>
            <Dropdown options={quantities} emptyMessage='Sin registros' placeholder='Selecciona la cantidad'
              name='recordsQuantity' value={recordsQuantity} onChange={onDropdownChange} className='p-inputtext-sm' />
            <ExportSalesToExcel sales={listItems} />
          </div>

          <DataTable value={listItems} tableStyle={{ minWidth: '70rem' }} scrollable scrollHeight='440px' stripedRows emptyMessage='Sin registro de ventas' size='small'>
            <Column field='id' header='Código' style={{ width: '9%' }}></Column>
            <Column field='shop' header='Local' style={{ width: '9%' }}></Column>
            <Column field='user' header='Usuario' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => rowData.quantity + ' unidad/es'} header='Vendidos' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatDate(rowData.date)} header='Fecha' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatTime(rowData.time)} header='Hora' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatCurrency(rowData.cashPayment)} header='Efectivo' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatCurrency(rowData.debitPayment)} header='Débito' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatCurrency(rowData.qrCodePayment)} header='Qr' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatCurrency(rowData.creditPayment)} header='Crédito' style={{ width: '9%' }}></Column>
            <Column field={(rowData) => formatCurrency(rowData.total)} header='Total' className='font-medium' style={{ width: '9%' }}></Column>
            <Column header='Acciones' style={{ width: '5%' }}
              body={(rowData) => (
                <div className='flex gap-1'>
                  <Link to={`/venta/detalle/${rowData.id}`} target='_blank'>
                    <Button className='!rounded' severity='primary' size='small'>
                      <i className='bi bi-eye-fill'></i>
                    </Button>
                  </Link>
                  <Button className='!rounded' severity='danger' size='small'
                    onClick={() => handleDelete(rowData.id)} >
                    <i className='bi bi-trash-fill'></i>
                  </Button>
                </div>
              )}>
            </Column>
          </DataTable>
          <Paginator first={first} rows={recordsQuantity} pageLinkSize={3} totalRecords={totalElements}
            onPageChange={onPageChange} className='mt-5 !p-0'></Paginator>
        </div>
      </div>

      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5'>
        <div className='!bg-[#348FE2] !text-white rounded p-[15px]'>
          <p className='text-xs mb-2'>Total efectivo</p>
          <span className='text-2xl font-medium'>{totalCash()}</span>
        </div>
        <div className='!bg-[#49B6D6] !text-white rounded p-[15px]'>
          <p className='text-xs mb-2'>Total débito</p>
          <span className='text-2xl font-medium'>{totalDebit()}</span>
        </div>
        <div className='!bg-[#F59C1A] !text-white rounded p-[15px]'>
          <p className='text-xs mb-2'>Total código Qr</p>
          <span className='text-2xl font-medium'>{totalCodeQr()}</span>
        </div>
        <div className='!bg-[#FF5B57] !text-white rounded p-[15px]'>
          <p className='text-xs mb-2'>Total crédito</p>
          <span className='text-2xl font-medium'>{totalCredit()}</span>
        </div>
        <div className='!bg-[#20252a] !text-white rounded p-[15px]'>
          <p className='text-xs mb-2'>Total de ventas</p>
          <span className='text-2xl font-medium'>{totalFinal()}</span>
        </div>
      </div>
    </div>
  )
}