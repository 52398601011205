import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext"
import { Paginator } from "primereact/paginator";
import { useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate, formatTime } from "../helper/format";


export const CostumersPage = () => {

  const [costumers, setCostumers] = useState([
    { fullname: 'Juan Pérez', phone: '+54 11 2345-6789', dni: '20.123.456', email: 'juan.perez@example.com' },
    { fullname: 'María López', phone: '+54 11 3456-7890', dni: '20.234.567', email: 'maria.lopez@example.com' },
    { fullname: 'Carlos García', phone: '+54 11 4567-8901', dni: '20.345.678', email: 'carlos.garcia@example.com' },
    { fullname: 'Ana Fernández', phone: '+54 11 5678-9012', dni: '20.456.789', email: 'ana.fernandez@example.com' },
    { fullname: 'Lucía Martínez', phone: '+54 11 6789-0123', dni: '20.567.890', email: 'lucia.martinez@example.com' },
    { fullname: 'Martín Gómez', phone: '+54 11 7890-1234', dni: '20.678.901', email: 'martin.gomez@example.com' },
    { fullname: 'Sofía Rodríguez', phone: '+54 11 8901-2345', dni: '20.789.012', email: 'sofia.rodriguez@example.com' },
    { fullname: 'Diego Hernández', phone: '+54 11 9012-3456', dni: '20.890.123', email: 'diego.hernandez@example.com' },
    { fullname: 'Valentina Ruiz', phone: '+54 11 0123-4567', dni: '20.901.234', email: 'valentina.ruiz@example.com' },
    { fullname: 'Gabriel Castro', phone: '+54 11 1234-5678', dni: '20.012.345', email: 'gabriel.castro@example.com' }
  ]);

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Mis clientes</h2>
        <span className="text-sm">Visualiza todas las compras realizadas por tus clientes (Los presentes datos son solo demostrativos)</span>
      </div>

      <div className='lg:flex gap-5'>
        <div className='lg:w-1/3 2xl:w-1/5'>
          <div className='panel'>
            <div className='panel-body'>
              <label className='block font-medium mb-1'>Buscar cliente</label>
              <div className='flex gap-3'>
                <InputText name="code" value={''} placeholder='Buscar por nombre, e-mail o DNI'
                  className='p-inputtext-sm w-full' onChange={''} required />
                <Button label='Buscar' type='submit' size='small' className='!rounded w-[80px]' />
              </div>
            </div>
          </div>

          <div className='panel'>
            <div className='panel-body'>
              <h4 className='font-medium pb-3'>Datos personales</h4>
              <div className='mb-3'>
                <label className='block mb-1'>Nombre y apellido</label>
                <InputText className='p-inputtext-sm w-full' name='fullname' value={''} onChange={''} />
              </div>
              <div className='mb-3'>
                <label className='block mb-1'>E-mail (opcional)</label>
                <InputText className='p-inputtext-sm w-full' name='email' value={''} onChange={''} />
              </div>
              <div className='flex gap-3 mb-3'>
                <div className='w-full'>
                  <label className='block mb-1'>Teléfono (opcional)</label>
                  <InputText className='p-inputtext-sm w-full' name='phone' value={''} onChange={''} />
                </div>
                <div className='w-full'>
                  <label className='block mb-1'>DNI o CUIL (opcional)</label>
                  <InputText className='p-inputtext-sm w-full' name='dni' value={''} onChange={''} />
                </div>
              </div>
              <div className='text-end'>
                <Button label='Agregar cliente' severity='primary' size='small' />
              </div>
            </div>
          </div>
        </div>

        <div className='lg:w-2/3 2xl:w-4/5'>
          <div className='panel'>
            <div className='panel-body h-[425px]'>
              <div className='flex items-center gap-3 mb-3'>
                <label className='block font-medium'>Cantidad</label>
                <Dropdown value={''} options={null} onChange={null}
                  name='recordsQuantity' className='p-inputtext-sm' />
              </div>
              <DataTable value={costumers} stripedRows scrollable scrollHeight='280px' size='small'>
                <Column field='dni' header='DNI' style={{ width: '23%' }} />
                <Column field='fullname' header='Nombre' style={{ width: '23%' }} />
                <Column field='email' header='E-mail' style={{ width: '23%' }} />
                <Column field='phone' header='Teléfono' style={{ width: '23%' }} />
                <Column style={{ width: '10%' }} body={(rowData) => (
                  <Button label='Ver info' severity='primary' outlined size='small'>
                  </Button>
                )}>
                </Column>
              </DataTable>
              <Paginator first={0} rows={10} totalRecords={100}
                onPageChange={null} pageLinkSize={3} className='mt-3 !p-0' />
            </div>
          </div>
        </div>
      </div>

      <div className='flex gap-5'>
        <div className='lg:w-1/3 2xl:w-1/5'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Detalles del cliente</span>
            </div>
            <div className='panel-body'>
              <div className='mb-3'>
                <p className='text-2xl font-medium mb-3'>Carlos Ezequiel García</p>
                <div>
                  <p>DNI: 20.345.678</p>
                  <p>E-mail: carlos.garcia@example.com</p>
                  <p>Teléfono: +54 11 4567-8901</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='lg:w-2/3 2xl:w-4/5'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Compras realizadas por el cliente</span>
            </div>
            <div className='panel-body h-[200px]'>
              <DataTable value={null} tableStyle={{ minWidth: '70rem' }} stripedRows emptyMessage='Sin registro de ventas' size='small'>
                <Column field='id' header='Código' style={{ width: '10%' }}></Column>
                <Column field='shop' header='Local' style={{ width: '10%' }}></Column>
                <Column field='user' header='Usuario' style={{ width: '10%' }}></Column>
                <Column field={(rowData) => rowData.quantity + ' unidad/es'} header='Vendidos' style={{ width: '10%' }}></Column>
                <Column field={(rowData) => formatDate(rowData.date)} header='Fecha' style={{ width: '10%' }}></Column>
                <Column field={(rowData) => formatTime(rowData.time)} header='Hora' style={{ width: '10%' }}></Column>
                <Column field={(rowData) => formatCurrency(rowData.cashPayment)} header='Efectivo' style={{ width: '9%' }}></Column>
                <Column field={(rowData) => formatCurrency(rowData.debitPayment)} header='Débito' style={{ width: '9%' }}></Column>
                <Column field={(rowData) => formatCurrency(rowData.qrCodePayment)} header='Qr' style={{ width: '9%' }}></Column>
                <Column field={(rowData) => formatCurrency(rowData.creditPayment)} header='Crédito' style={{ width: '9%' }}></Column>
                <Column field={(rowData) => formatCurrency(rowData.total)} header='Total' className='font-medium' style={{ width: '9%' }}></Column>
                <Column header='Acciones' style={{ width: 'auto' }}
                  body={(rowData) => (
                    <button className='hover:text-blue-500'>Ver más</button>
                  )}>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

/* Formulario de cliente 
          <div className='panel'>
            <div className='panel-body'>
              <h4 className='font-medium pb-3'>Agregar cliente</h4>
              <div className='mb-3'>
                <label className='block mb-1'>Nombre y apellido</label>
                <InputText className='p-inputtext-sm w-full' name='fullname' value={''} onChange={''} />
              </div>
              <div className='mb-3'>
                <label className='block mb-1'>E-mail (opcional)</label>
                <InputText className='p-inputtext-sm w-full' name='email' value={''} onChange={''} />
              </div>
              <div className='flex gap-3'>
                <div className='w-full'>
                  <label className='block mb-1'>Teléfono (opcional)</label>
                  <InputText className='p-inputtext-sm w-full' name='phone' value={''} onChange={''} />
                </div>
                <div className='w-full'>
                  <label className='block mb-1'>DNI o CUIL (opcional)</label>
                  <InputText className='p-inputtext-sm w-full' name='dni' value={''} onChange={''} />
                </div>
              </div>
            </div>
          </div>
          <div className='text-end'>
            <Button label='Agregar' severity='primary' size='small' />
          </div>
*/