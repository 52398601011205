import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDWJjiy-FxK1LhUOMMGLxM6D4AosYpDe6o",
  authDomain: "gustock-chat.firebaseapp.com",
  projectId: "gustock-chat",
  storageBucket: "gustock-chat.appspot.com",
  messagingSenderId: "836720357005",
  appId: "1:836720357005:web:de613b4686da0371c1fcc4"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();