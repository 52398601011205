import { useEffect, useState } from 'react'

import { Card } from 'primereact/card'

import { formatCurrency, formatFullDate, formatTime } from '../../../helper/format'

import SaleService from '../../../services/sale.service'

const saleService = new SaleService()

const LastSaleComponent = ({ user, shop, reload }) => {

  const [sale, setSale] = useState([])
  const [show, setShow] = useState(false)
  const [details, setDetails] = useState([])
  const [refunds, setRefunds] = useState([])

  useEffect(() => {
    saleService.getLastByUserAndShop(user, shop)
      .then(data => {
        setSale(data)
        setShow(true)
        setDetails(data.details)
        setRefunds(data?.refunds)
      })
      .catch(error => {
        console.log(error)
      })
  }, [reload])

  return (
    <div>
      {show ?
        <div>
          <div className='md:flex'>
            <div className='md:w-1/3 lg:w-1/4 xl:w-1/5 me-5 mb-5 md:mb-0 sm:flex md:block gap-5'>
              <div className='bg-white rounded border p-[15px] mb-5'>
                <div>
                  <h2 className='!text-[16px] font-medium mb-1'>Resumen de última venta #{sale.id}</h2>
                  <span className='text-sm'>{formatFullDate(sale.date)}</span>
                </div>
                <hr className='my-3'></hr>
                <div>
                  <div className='mb-3'>
                    <h3 className='text-sm font-medium'>Información</h3>
                  </div>
                  <div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Local</span>
                      <p className='text-sm'>{sale.shop}</p>
                    </div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Usuario</span>
                      <p className='text-sm'>{sale.user}</p>
                    </div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Horario</span>
                      <p className='text-sm'>{formatTime(sale.time)}</p>
                    </div>
                    {sale.note ?
                      <div>
                        <span className='text-gray-500 text-sm'>Nota del vendedor: </span>
                        <p className='inline text-sm'>{sale.note}</p>
                      </div>
                      :
                      <div className='flex justify-between'>
                        <span className='text-gray-500 text-sm'>Nota del vendedor: </span>
                        <p className='text-sm'>{sale.note ? sale.note : '-'}</p>
                      </div>
                    }
                  </div>
                </div>
                <hr className='my-3'></hr>
                <div>
                  <div className='mb-3'>
                    <h3 className='text-sm font-medium'>Detalle de pago</h3>
                  </div>

                  <div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Descuento</span>
                      <p className='text-sm'>{formatCurrency(sale.discount)}</p>
                    </div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Efectivo</span>
                      <p className='text-sm'>{formatCurrency(sale.cashPayment)}</p>
                    </div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Débito</span>
                      <p className='text-sm'>{formatCurrency(sale.debitPayment)}</p>
                    </div>
                    <div className='flex justify-between mb-1'>
                      <span className='text-gray-500 text-sm'>Crédito</span>
                      <p className='text-sm'>{formatCurrency(sale.creditPayment)}</p>
                    </div>
                    <div className='flex justify-between mb-3'>
                      <span className='text-gray-500 text-sm'>Código Qr</span>
                      <p className='text-sm'>{formatCurrency(sale.qrCodePayment)}</p>
                    </div>
                    <div className='flex justify-between'>
                      <span className='font-medium text-blue-900'>Total pagado:</span>
                      <p className='font-medium text-blue-900'>{formatCurrency(sale.total)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='md:w-2/3 lg:w-3/4 xl:w-4/5'>
              <div className='bg-white border rounded-md mb-5 p-[15px]'>
                <div className='mb-3'>
                  <h3 className='text-sm font-medium'>Productos vendidos</h3>
                </div>

                <div>
                  {details.map(product => (
                    <div className='border-b pb-3 mb-3'>
                      <div className='lg:flex text-sm items-center text rounded-md'>
                        <div className='flex-auto mb-5 lg:mb-0'>
                          <p>{product.description}</p>
                          <span>Código: {product.code}</span>
                        </div>
                        <div className='sm:flex gap-5'>
                          <p className='flex-auto lg:text-center font-medium'>Precio:
                            <span className="font-normal"> {formatCurrency(product.price)}</span>
                          </p>
                          <p className='flex-auto lg:text-center font-medium'>Cantidad:
                            <span className="font-normal"> {product.quantity}</span>
                          </p>
                          <p className='flex-auto lg:text-center font-medium'>Subtotal:
                            <span className='font-normal'> {formatCurrency(product.subtotal)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {refunds.length > 0 &&
                <div className='bg-white border rounded-md mb-5 p-[15px]'>
                  <div className='mb-3'>
                    <h3 className='text-sm font-medium'>Productos devueltos</h3>
                  </div>

                  <div>
                    {refunds.map(product => (
                      <div className='border-b pb-3 mb-3'>
                        <div className='lg:flex text-sm items-center text rounded-md'>
                          <div className='flex-auto mb-5 lg:mb-0'>
                            <p>{product.description}</p>
                            <span>Código: {product.code}</span>
                          </div>
                          <div className='sm:flex gap-5'>
                            <p className='flex-auto lg:text-center font-medium'>Precio:
                              <span className="font-normal"> {formatCurrency(product.price)}</span>
                            </p>
                            <p className='flex-auto lg:text-center font-medium'>Cantidad:
                              <span className="font-normal"> {product.quantity}</span>
                            </p>
                            <p className='flex-auto lg:text-center font-medium'>Subtotal:
                              <span className='font-normal'> {formatCurrency(product.subtotal)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className='panel'>
          <div className='panel-body'>
            <p className='text-center text-sm font-medium'>Todavía no has realizado tú primera venta el día de hoy</p>
          </div>
        </div>
      }
    </div>
  )
}

export default LastSaleComponent;