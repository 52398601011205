import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext"
import { useState } from "react";


export const CostumListPage = () => {

    const [items, setItems] = useState([
        { name: 'Lista 1', lastUpdate: '2024-07-01 08:00:00' },
        { name: 'Lista 2', lastUpdate: '2024-07-02 09:00:00' },
        { name: 'Lista 3', lastUpdate: '2024-07-03 10:00:00' },
        { name: 'Lista 4', lastUpdate: '2024-07-04 11:00:00' },
        { name: 'Lista 5', lastUpdate: '2024-07-05 12:00:00' },
        { name: 'Lista 6', lastUpdate: '2024-07-06 13:00:00' },
        { name: 'Lista 7', lastUpdate: '2024-07-07 14:00:00' },
        { name: 'Lista 8', lastUpdate: '2024-07-08 15:00:00' },
        { name: 'Lista 9', lastUpdate: '2024-07-09 16:00:00' },
        { name: 'Lista 10', lastUpdate: '2024-07-10 17:00:00' }
    ]);

    const [products, setProducts] = useState([

    ]);

    return (
        <div>
            <div className='pb-[15px]'>
                <h2 className='text-2xl mb-1'>Mis listas personalizadas</h2>
                <span className="text-sm">Crea diferentes listas de productos acorde a tus necesidades (Los presentes datos son solo demostrativos)</span>
            </div>

            <div className='lg:flex gap-5'>
                <div className='lg:w-1/4'>
                    <div className='panel'>
                        <div className='panel-body'>
                            <DataTable value={items} stripedRows size='small'>
                                <Column field='name' header='Nombre de lista' style={{ width: '50%' }} />
                                <Column field='lastUpdate' header='Última modifcación' style={{ width: '50%' }} />
                                <Column body={(rowData) => (
                                    <Button severity='primary' size='small'>
                                        <i className='bi bi-eye-fill'></i>
                                    </Button>
                                )}>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div className='lg:w-3/4'>
                    <div className='panel'>
                        <div className='panel-body h-[600px]'>
                            <DataTable value={products} stripedRows size='small'>
                                <Column field='name' header='Código' style={{ width: '20%' }} />
                                <Column field='lastUpdate' header='Descripción' style={{ width: '30%' }} />
                                <Column field='lastUpdate' header='Rubro' style={{ width: '20%' }} />
                                <Column field='' header='Local 1' style={{ width: '10%' }} />
                                <Column field='' header='Local 2' style={{ width: '10%' }} />
                                <Column field='' header='Local 3' style={{ width: '10%' }} />
                                <Column body={(rowData) => (
                                    <Button severity='primary' size='small'>
                                        <i className='bi bi-eye-fill'></i>
                                    </Button>
                                )}>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}