import { useEffect, useState } from 'react'

import { Card } from 'primereact/card'
import { useParams } from 'react-router-dom'

import { formatCurrency, formatFullDate, formatTime } from '../../helper/format'

import SaleService from '../../services/sale.service'

const saleService = new SaleService()

export const SaleDetailPage = () => {

  const { id } = useParams()

  const [sale, setSale] = useState([])
  const [details, setDetails] = useState([])
  const [refunds, setRefunds] = useState([])

  useEffect(() => {
    loadSale()
  }, []);

  const loadSale = () => {
    saleService.getById(id).then(data => {
      setSale(data)
      setDetails(data.details)
      setRefunds(data?.refunds)
    })
  }

  return (
    <div>
      <div className='mb-5'>
        <h2 className='text-2xl font-medium mb-2'>Resumen de venta #{sale.id}</h2>
        <span>{formatFullDate(sale.date)}</span>
      </div>
      <div className='md:flex'>
        <div className='md:w-1/3 lg:w-1/4 xl:w-1/5 me-5 mb-5 md:mb-0 sm:flex md:block gap-5'>
          <Card className='!shadow-none border w-full mb-5 sm:mb-0 md:mb-5'>
            <div className='bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 p-4 rounded-md'>
              <h3 className='!text-white font-medium text-xl mb-0'>Detalle de pago</h3>
            </div>
            
            <div className='px-4 pt-4'>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Descuento</span>
                <p className='text-lg'>{formatCurrency(sale.discount)}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Efectivo</span>
                <p className='text-lg'>{formatCurrency(sale.cashPayment)}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Débito</span>
                <p className='text-lg'>{formatCurrency(sale.debitPayment)}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Crédito</span>
                <p className='text-lg'>{formatCurrency(sale.creditPayment)}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Código Qr</span>
                <p className='text-lg'>{formatCurrency(sale.qrCodePayment)}</p>
              </div>
            </div>
            <hr className='my-4'></hr>
            <div className='text-end pe-4'>
              <span className='text-xl font-medium text-blue-900 mb-0'>Total pagado: {formatCurrency(sale.total)}</span>
            </div>
          </Card>

          <Card className='!shadow-none border w-full lg:mt-5'>
            <div className='bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 p-4 rounded-md'>
              <h3 className='!text-white font-medium text-xl mb-0'>Información adicional</h3>
            </div>
            <div className='p-4'>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Local</span>
                <p className='text-md'>{sale.shop}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Usuario</span>
                <p className='text-md'>{sale.user}</p>
              </div>
              <div className='flex justify-between mb-1'>
                <span className='text-gray-500 text-md font-medium'>Horario</span>
                <p className='text-md'>{formatTime(sale.time)}</p>
              </div>
              <div>
                <span className='text-gray-500 text-md font-medium'>Nota del vendedor:</span>
                <div className='border rounded mt-3 p-5'>
                  <p className='text-md'>{sale.note ? sale.note : 'Sin comentarios'}</p>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='md:w-2/3 lg:w-3/4 xl:w-4/5'>
          <div className='border-b border-[#1d64d8] mb-5'>
            <h3 className='text-xl font-bold mb-2'>Productos vendidos</h3>
          </div>

          {details.map(product => (
            <Card className='!shadow-none border mb-5'>
              <div className='lg:flex items-center text-lg rounded-md'>
                <div className='flex-auto mb-5 lg:mb-0'>
                  <p className='font-medium'>{product.description}</p>
                  <span className='!text-sm'>Código: {product.code}</span>
                </div>
                <div className='sm:flex gap-5'>
                  <p className='flex-auto lg:text-center font-medium'>Precio:
                    <span className="font-normal"> {formatCurrency(product.price)}</span>
                  </p>
                  <p className='flex-auto lg:text-center font-medium'>Cantidad:
                    <span className="font-normal"> {product.quantity}</span>
                  </p>
                  <p className='flex-auto lg:text-center font-medium'>Subtotal:
                    <span className='font-normal'> {formatCurrency(product.subtotal)}</span>
                  </p>
                </div>
              </div>
            </Card>
          ))}

          {refunds.length > 0 &&
            <div className='border-b border-[#1d64d8] mb-5'>
              <h3 className='text-xl font-bold mb-2'>Productos devueltos</h3>
            </div>
          }

          {refunds.length > 0 &&
            refunds.map(product => (
              <Card className='!shadow-none border mb-5'>
                <div className='lg:flex items-center text-lg rounded-md'>
                  <div className='flex-auto mb-5 lg:mb-0'>
                    <p className='font-medium'>{product.description}</p>
                    <span className='!text-sm'>Código: {product.code}</span>
                  </div>
                  <div className='sm:flex gap-5'>
                    <p className='flex-auto lg:text-center font-medium'>Precio:
                      <span className="font-normal"> {formatCurrency(product.price)}</span>
                    </p>
                    <p className='flex-auto lg:text-center font-medium'>Cantidad:
                      <span className="font-normal"> {product.quantity}</span>
                    </p>
                    <p className='flex-auto lg:text-center font-medium'>Subtotal:
                      <span className='font-normal'> {formatCurrency(product.subtotal)}</span>
                    </p>
                  </div>
                </div>
              </Card>
            ))
          }
        </div>
      </div>
    </div>
  )
}
