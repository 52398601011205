import { useContext, useEffect, useState } from 'react'

import { Check } from 'lucide-react'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { useParams } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { useRequest } from '../../hooks/use.request'
import { InputTextarea } from 'primereact/inputtextarea'

import Swal from 'sweetalert2'
import UserContext from '../../user.context'
import { formatCurrency } from '../../helper/format'

import SaleService from '../../services/sale.service'
import ProductService from '../../services/product.service'

const saleService = new SaleService()
const productService = new ProductService()

export const SaleChangePage = () => {
  const { name } = useParams()
  const [user] = useContext(UserContext)

  const [note, setNote] = useState('')
  const [total, setTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [discount, setDiscount] = useState(0)

  const [cashPayment, setCashPayment] = useState(0)
  const [debitPayment, setDebitPayment] = useState(0)
  const [creditPayment, setCreditPayment] = useState(0)
  const [qrCodePayment, setQrCodePayment] = useState(0)

  const [discountPct, setDiscountPct] = useState(0)
  const [additionalPct, setAdditionalPct] = useState(0)

  const [listProducts, setListProducts] = useState([])
  const [listRefunds, setListRefunds] = useState([])

  const initialRequest = {
    shop: name,
    code: null,
    typePrice: 'CONTADO'
  }

  const prices = ['CONTADO', 'DEBITO', 'CREDITO']

  const { requestState, onDropdownChange, onInputChange } = useRequest(initialRequest)
  const { code, typePrice } = requestState

  useEffect(() => {
    const subtotalRefund = listRefunds.reduce((total, product) => total + product.subtotal, 0)
    const subtotalProducts = listProducts.reduce((total, product) => total + product.subtotal, 0)
    const newSubtotal = subtotalProducts - subtotalRefund
    setSubtotal(newSubtotal)
  }, [listProducts, listRefunds])

  useEffect(() => {
    calculateDiscount()
  }, [subtotal, discountPct])

  useEffect(() => {
    if (listProducts.length > 0)
      calculatePayment()
  }, [cashPayment, debitPayment, qrCodePayment, additionalPct])

  const handleSearchProduct = (e) => {
    e.preventDefault()
    if (listProducts.some(product => product.code === code.trim())) {
      Swal.fire('Producto ya agregado', 'Este producto ya ha sido agregado a la venta.', 'error',)
    } else {
      productService.getByCodeOrBarcode(requestState).then(data => {
        setListProducts(prevList => [...prevList, data])
      }).catch((error) => {
        Swal.fire('Producto no encontrado', 'No se encontró ningún producto que coincida con el artículo o código de barras ingresado.', 'error',)
      })
    }
  }

  const handleSearchRefund = (e) => {
    e.preventDefault()
    if (listRefunds.some(product => product.code === code.trim())) {
      Swal.fire('Producto ya agregado', 'Este producto ya ha sido agregado a la venta.', 'error',)
    } else {
      productService.getByCodeOrBarcode(requestState).then(data => {
        setListRefunds(prevList => [...prevList, data])
      }).catch((error) => {
        Swal.fire('Producto no encontrado', 'No se encontró ningún producto que coincida con el artículo o código de barras ingresado.', 'error',)
      })
    }
  }

  const handleDeleteProduct = (product) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el producto de la lista',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setCashPayment(0)
        setDebitPayment(0)
        setCreditPayment(0)
        setQrCodePayment(0)
        setListProducts(prevList => prevList.filter((item) => item.code !== product.code))
        Swal.fire('Eliminado', 'Se elimino el producto de la lista.', 'success')
      }
    })
  }

  const handleDeleteRefund = (refund) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el producto de la lista',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        setListRefunds(prevList => prevList.filter((item) => item.code !== refund.code))
        Swal.fire('Eliminado', 'Se elimino el producto de la lista.', 'success')
      }
    })
  }

  const calculateDiscount = () => {
    const discountAmount = subtotal * (discountPct / 100);
    setDiscount(discountAmount)
    setTotal(subtotal - discountAmount)
  }

  const calculatePayment = () => {
    const rest = cashPayment + debitPayment + qrCodePayment
    const newValue = total - rest

    if (additionalPct > 0) {
      const additional = (newValue * additionalPct) / 100
      setCreditPayment(newValue + additional)
    }
  }

  const onPriceChange = (rowData) => (e) => {
    const newPrice = e.value
    const newSubtotal = rowData.quantity * newPrice

    setListProducts(prevList => prevList.map(
      item => (item.code === rowData.code ? { ...item, price: newPrice, subtotal: newSubtotal } : item)));
  }

  const onQuantityChange = (rowData) => (e) => {
    const newQuantity = e.value
    const newSubtotal = newQuantity * rowData.price

    setListProducts(prevList => prevList.map(
      item => (item.code === rowData.code ? { ...item, quantity: newQuantity, subtotal: newSubtotal } : item)));
  }

  const onPriceChangeRefund = (rowData) => (e) => {
    const newPrice = e.value
    const newSubtotal = rowData.quantity * newPrice

    setListRefunds(prevList => prevList.map(
      item => (item.code === rowData.code ? { ...item, price: newPrice, subtotal: newSubtotal } : item)));
  }

  const onQuantityChangeRefund = (rowData) => (e) => {
    const newQuantity = e.value
    const newSubtotal = newQuantity * rowData.price

    setListRefunds(prevList => prevList.map(
      item => (item.code === rowData.code ? { ...item, quantity: newQuantity, subtotal: newSubtotal } : item)));
  }


  const handleCreateSale = () => {
    if (listProducts.length === 0) {
      Swal.fire('Error', 'No hay productos agregados a la venta.', 'error');
      return;
    }

    const sale = {
      user: user.sub,
      shop: name,
      note: note,
      total: cashPayment + debitPayment + qrCodePayment + creditPayment,
      subtotal: subtotal,
      discount: discount,
      refunds: listRefunds,
      details: listProducts,
      cashPayment: cashPayment,
      debitPayment: debitPayment,
      creditPayment: creditPayment,
      qrCodePayment: qrCodePayment,
    }

    const detailPayment = `
    <div style="display: flex; justify-content: center;">
      <table style="border-collapse: collapse;">
        <tr>
          <td style="padding: 8px; text-align: left; font-weight: 600;">Contado:</td>
          <td style="padding: 8px; text-align: right;">${formatCurrency(cashPayment)}</td>
        </tr>
        <tr>
          <td style="padding: 8px; text-align: left; font-weight: 600;">Débito:</td>
          <td style="padding: 8px; text-align: right;">${formatCurrency(debitPayment)}</td>
        </tr>
        <tr>
          <td style="padding: 8px; text-align: left; font-weight: 600;">Crédito:</td>
          <td style="padding: 8px; text-align: right;">${formatCurrency(creditPayment)}</td>
        </tr>
        <tr>
          <td style="padding: 8px; text-align: left; font-weight: 600;">QR:</td>
          <td style="padding: 8px; text-align: right;">${formatCurrency(qrCodePayment)}</td>
        </tr>
      </table>
    </div>`

    Swal.fire({
      title: 'Verifique el pago',
      html: detailPayment,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.isConfirmed) {
        saleService.create(sale).then(data => {
          setNote('')
          setDiscountPct(0)
          setCashPayment(0)
          setDebitPayment(0)
          setCreditPayment(0)
          setQrCodePayment(0)
          setAdditionalPct(0)
          setListProducts([])
          setListRefunds([])
          Swal.fire('Registrado', 'Se ha registrado la venta con éxito. <br> Número de venta: ' + data, 'success')
        }).catch((error) => {
          Swal.fire('Error', 'Hubo un problema al intentar registrar la venta. Por favor, inténtelo de nuevo más tarde.', 'error')
        })
      }
    })
  }

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl'>{name} | Cambio de producto</h2>
      </div>

      <div className='lg:flex gap-5'>
        <div className='lg:w-3/4'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Productos que devuelve</span>
            </div>

            <div className='panel-body'>
              <form onSubmit={handleSearchRefund} className='mb-3'>
                <div className='flex items-center gap-3'>
                  <label className='font-medium'>Agregar producto</label>
                  <InputText name='code' placeholder='Artículo o código de barras'
                    className='p-inputtext-sm w-[300px]' onChange={onInputChange} required />
                  <Dropdown value={typePrice} options={prices} onChange={onDropdownChange}
                    name='typePrice' className='p-inputtext-sm' />
                  <Button label='Agregar' type='submit' size='small'></Button>
                </div>
              </form>

              <DataTable value={listRefunds} stripedRows
                emptyMessage='No se agregaron productos' size='small'>
                <Column field='code' header='Código' style={{ width: '10%' }} />
                <Column field='description' header='Descripción' style={{ width: '45%' }} />
                <Column field='stock' header='Stock' style={{ width: '5%' }} />

                <Column field='price' header='Precio unitario' style={{ width: '20%' }}
                  body={(rowData) => (
                    <InputNumber minFractionDigits={0} maxFractionDigits={0}
                      value={rowData.price} onChange={onPriceChangeRefund(rowData)}
                      mode='currency' currency='ARS' locale='es-AR' inputClassName='p-inputtext-sm w-full' />
                  )}>
                </Column>

                <Column field='quantity' header='Cantidad' style={{ width: '9%' }}
                  body={(rowData) => (
                    <InputNumber value={rowData.quantity} onChange={onQuantityChangeRefund(rowData)} step={1} min={1}
                      showButtons buttonLayout='horizontal' inputClassName='p-inputtext-sm text-center w-12'
                      decrementButtonClassName='p-button-secondary' decrementButtonIcon='pi pi-minus'
                      incrementButtonClassName='p-button-info' incrementButtonIcon='pi pi-plus' />
                  )}>
                </Column>

                <Column field={(rowData) => formatCurrency(rowData.subtotal)} header='Subtotal' style={{ width: '10%' }} />

                <Column style={{ width: '1%' }}
                  body={(rowData) => (
                    <Button className='!rounded' severity='danger' size='small'
                      onClick={() => handleDeleteRefund(rowData)} >
                      <i className='bi bi-trash-fill'></i>
                    </Button>
                  )}>
                </Column>
              </DataTable>
            </div>
          </div>

          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Productos que lleva</span>
            </div>

            <div className='panel-body'>
              <form onSubmit={handleSearchProduct} className='mb-3'>
                <div className='flex items-center gap-3'>
                  <label className='font-medium'>Agregar producto</label>
                  <InputText name='code' placeholder='Artículo o código de barras'
                    className='p-inputtext-sm w-[300px]' onChange={onInputChange} required />
                  <Dropdown value={typePrice} options={prices} onChange={onDropdownChange}
                    name='typePrice' className='p-inputtext-sm' />
                  <Button label='Agregar' type='submit' size='small'></Button>
                </div>
              </form>

              <DataTable value={listProducts} stripedRows
                emptyMessage='No se agregaron productos' size='small'>
                <Column field='code' header='Código' style={{ width: '10%' }} />
                <Column field='description' header='Descripción' style={{ width: '45%' }} />
                <Column field='stock' header='Stock' style={{ width: '5%' }} />

                <Column field='price' header='Precio unitario' style={{ width: '20%' }}
                  body={(rowData) => (
                    <InputNumber minFractionDigits={0} maxFractionDigits={0}
                      value={rowData.price} onChange={onPriceChange(rowData)}
                      mode='currency' currency='ARS' locale='es-AR' inputClassName='p-inputtext-sm w-full' />
                  )}>
                </Column>

                <Column field='quantity' header='Cantidad' style={{ width: '5%' }}
                  body={(rowData) => (
                    <InputNumber value={rowData.quantity} onChange={onQuantityChange(rowData)} step={1} min={1}
                      showButtons buttonLayout='horizontal' inputClassName='p-inputtext-sm text-center w-12'
                      decrementButtonClassName='p-button-secondary' decrementButtonIcon='pi pi-minus'
                      incrementButtonClassName='p-button-info' incrementButtonIcon='pi pi-plus' />
                  )}>
                </Column>

                <Column field={(rowData) => formatCurrency(rowData.subtotal)} header='Subtotal' style={{ width: '10%' }} />

                <Column style={{ width: '5%' }}
                  body={(rowData) => (
                    <Button className='!rounded' severity='danger' size='small'
                      onClick={() => handleDeleteProduct(rowData)} >
                      <i className='bi bi-trash-fill'></i>
                    </Button>
                  )}>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>

        <div className='lg:w-1/4'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Resumen de cuenta</span>
            </div>

            <div className='panel-body text-sm'>
              <div className='mb-3'>
                <InputTextarea value={note} onChange={(e) => setNote(e.value)} rows={4}
                  placeholder='Nota interna (Opcional)' className='w-full' />
              </div>
              <div class='flex flex-col border rounded py-3 px-5'>
                <div class='flex justify-between items-center mb-1'>
                  <span class='font-medium'>Porcentaje de descuento</span>
                  <InputNumber value={discountPct} onChange={(e) => setDiscountPct(e.value)} suffix='%' size={1} className='p-inputtext-sm' />
                </div>
                <div class='flex justify-between mb-1'>
                  <span>Descuento</span>
                  <span id='descuento-span'>{formatCurrency(discount)}</span>
                </div>
                <div class='flex justify-between mb-1'>
                  <span>Subtotal</span>
                  <span id='subtotal'>{formatCurrency(subtotal)}</span>
                </div>
                <hr className='my-3'></hr>
                <div class='flex justify-between font-bold'>
                  <span>TOTAL</span>
                  <span id='total'>{formatCurrency(total)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Detalle de pago</span>
            </div>

            <div className='panel-body text-sm'>
              <div className='border rounded py-3 px-5'>
                <div class='flex items-center justify-between mb-3'>
                  <span className='font-medium'>Contado</span>
                  <InputNumber value={cashPayment} onChange={(e) => setCashPayment(e.value)}
                    mode='currency' currency='ARS' locale='es-AR' className='p-inputtext-sm'
                    minFractionDigits={0} maxFractionDigits={0} size={10} />
                </div>
                <div class='flex items-center justify-between mb-3'>
                  <span className='font-medium'>Débito</span>
                  <InputNumber value={debitPayment} onChange={(e) => setDebitPayment(e.value)}
                    mode='currency' currency='ARS' locale='es-AR' className='p-inputtext-sm'
                    minFractionDigits={0} maxFractionDigits={0} size={10} />
                </div>
                <div class='flex items-center justify-between mb-3'>
                  <span className='font-medium'>QR</span>
                  <InputNumber value={qrCodePayment} onChange={(e) => setQrCodePayment(e.value)}
                    mode='currency' currency='ARS' locale='es-AR' className='p-inputtext-sm'
                    minFractionDigits={0} maxFractionDigits={0} size={10} />
                </div>
                <div class='flex items-center justify-between mb-3'>
                  <span className='font-medium'>Recargo</span>
                  <InputNumber value={additionalPct} onChange={(e) => setAdditionalPct(e.value)}
                    suffix='%' className='p-inputtext-sm'
                    minFractionDigits={0} maxFractionDigits={0} size={1} />
                </div>
                <div class='flex items-center justify-between'>
                  <span className='font-medium'>Crédito</span>
                  <InputNumber value={creditPayment} onChange={(e) => setCreditPayment(e.value)}
                    mode='currency' currency='ARS' locale='es-AR' className='p-inputtext-sm'
                    minFractionDigits={0} maxFractionDigits={0} size={10} />
                </div>
              </div>
            </div>

            <div className='bg-[#495057] p-[15px] rounded-b'>
              <button onClick={handleCreateSale} className='bg-blue-500 text-white rounded w-full py-[7px] px-[12px]'>
                <i class="text-[20px] pi pi-shopping-cart"></i>
                <p className='font-medium inline pl-3'>Registrar venta</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
