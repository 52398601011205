import axios from "axios"

import { BASE_URL } from "../helper/axion.helper"

const baseUrl = BASE_URL + "/api/v1/shop"

class ShopService{

    getById(id){return axios.get(baseUrl + "/" + id).then(res => res.data)}

    getByName(name){return axios.get(baseUrl + "/getByName/" + name).then(res => res.data)}

    create(shop){return axios.post(baseUrl + "/createShop", shop).then(res => res.data)}

    update(shop){return axios.put(baseUrl + "/updateShop", shop).then(res => res.data)}

    delete(id){return axios.delete(baseUrl + "/deleteShop/" + id).then(res => res.data)}

    getAll(){return axios.get(baseUrl + "/getAllShops").then(res => res.data)}

    getAllByTypeShop(){return axios.get(baseUrl + "/getAllShopsByTypeLocal").then(res => res.data)}
        
}

export default ShopService;
