import { useState, useEffect } from 'react';

import ShopService from '../services/shop.service';
import BrandService from '../services/brand.service';
import CategoryService from '../services/category.service';
import ProviderService from '../services/provider.service';

const shopService = new ShopService()
const brandService = new BrandService()
const categoryService = new CategoryService()
const providerService = new ProviderService()

function ProductFilters() {

  const [listShops, setShops] = useState([])
  const [listBrands, setListBrands] = useState([])
  const [listProviders, setListProviders] = useState([])
  const [listCategories, setListCategories] = useState([])
  const [listQuantities] = useState([10, 20, 30, 40, 50, "TODOS"])

  useEffect(() => {
    const fetchProveedores = providerService.getAll().then((res) => {
      const newList = res.map((provider) => provider.businessName)
      newList.unshift('TODOS')
      setListProviders(newList)
    });

    const fetchRubros = categoryService.getAll().then((res) => {
      const newList = res.map((category) => category.description)
      newList.unshift('TODOS')
      setListCategories(newList)
    });

    const fetchMarcas = brandService.getAll().then((res) => {
      const newList = res.map((brand) => brand.description)
      newList.unshift('TODOS')
      setListBrands(newList)
    });

    const fetchLocales = shopService.getAllByTypeShop().then((res) => {
      const newList = res.map((shop) => shop.name)
      newList.unshift('TODOS')
      setShops(newList);
    });

    Promise.all([fetchProveedores, fetchRubros, fetchMarcas, fetchLocales])
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return { listShops, listBrands, listProviders, listCategories, listQuantities }

}

export default ProductFilters;




