import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import { RadioButton } from 'primereact/radiobutton'
import { InputNumber } from 'primereact/inputnumber'

import { usePagination } from '../../hooks/use.pagination'
import { formatCurrency, formatDateTime } from '../../helper/format'

import ProductFilters from '../../utils/filters.product'
import ProductService from '../../services/product.service'

const productService = new ProductService()

export const QuickUpdatePage = () => {

  const initialPagination = {
    page: 0,
    brand: 'TODOS',
    category: 'TODOS',
    provider: 'TODOS',
    recordsQuantity: 10
  }

  const [first, setFirst] = useState(0)
  const [listProducts, setListProducts] = useState([])
  const [totalElements, setTotalElements] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])

  const { paginationState, onDropdownChange, setPaginationState } = usePagination(initialPagination)
  const { provider, category, brand, recordsQuantity } = paginationState

  const { listProviders, listCategories, listBrands, listQuantities } = ProductFilters();

  /* Params request to update prices */
  const [option, setOption] = useState(null)
  const [pctCash, setPctCash] = useState(null)
  const [pctDebit, setPctDebit] = useState(null)
  const [pctCredit, setPctCredit] = useState(null)

  useEffect(() => {
    loadProducts()
  }, [paginationState])

  const loadProducts = () => {
    productService.getAll(paginationState).then(data => {
      setListProducts(data.content)
      setTotalElements(data.totalElements)
    })
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const handleUpdatePrices = () => {
    const selectedProductIds = selectedProducts.map(product => product.id)

    if (pctDebit === null && pctCredit === null && pctCash === null) {
      Swal.fire('Error', 'Debe ingresar al menos un valor de porcentaje.<br> (Efectivo, Débito, Crédito)', 'error');
      return;
    }

    if (!option) {
      Swal.fire('Error', 'Debe seleccionar una acción a realizar.<br> (Aumentar o Disminuir)', 'error');
      return;
    }

    if (selectedProductIds.length === 0) {
      Swal.fire('Error', 'Debe seleccionar al menos un producto.', 'error');
      return;
    }

    const request = {
      action: option,
      pctCash: pctCash,
      pctDebit: pctDebit,
      pctCredit: pctCredit,
      productsIds: selectedProductIds
    }

    productService.updatePrices(request)
      .then((data) => {
        loadProducts()
        setSelectedProducts([])
        Swal.fire('Actualizado', 'Se han actualizado ' + data + ' productos con éxito.', 'success')
      })
      .catch((error) => {
        Swal.fire('Error', 'Hubo un problema al intentar actualizar los productos. Por favor, inténtelo de nuevo más tarde.', 'error')
      })
  }

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Actualización rápida de precios</h2>
        <span className='text-sm'>Presiona la casilla para seleccionar el producto. Los artículos seleccionados se actualizarán al presionar "Actualizar"</span>
      </div>

      <div className='md:flex gap-5'>
        <div className='md:w-1/4 xl:w-1/5 2xl:w-1/6 mb-5 lg:mb-0'>
          <div className='panel'>
            <div className='panel-heading'>
              <span className='font-medium'>Variables de operación</span>
            </div>
            <div className='panel-body'>
              <div className='mb-3'>
                <span className='text-sm font-medium'>Ingreso de porcentajes</span>
              </div>
              <div className='flex flex-wrap gap-3 md:block mb-3'>
                <div className='flex items-center justify-between gap-3 md:mb-3'>
                  <label className='font-medium'>Efectivo</label>
                  <InputNumber name='pctEffective' className='p-inputtext-sm' suffix="%"
                    value={pctCash} onChange={(e) => setPctCash(e.value)} size={6} />
                </div>
                <div className='flex items-center justify-between gap-3 md:mb-3'>
                  <label className='font-medium'>Débito</label>
                  <InputNumber name='pctDebit' className='p-inputtext-sm' suffix="%"
                    value={pctDebit} onChange={(e) => setPctDebit(e.value)} size={6} />
                </div>
                <div className='flex items-center justify-between gap-3'>
                  <label className='font-medium'>Crédito</label>
                  <InputNumber name='pctCredit' className='p-inputtext-sm' suffix="%"
                    value={pctCredit} onChange={(e) => setPctCredit(e.value)} size={6} />
                </div>
              </div>
              <hr className='my-3'></hr>
              <div className='mb-3'>
                <span className='text-sm font-medium'>Acción a realizar</span>
              </div>
              <div className='flex gap-3 md:block md:mb-3'>
                <div className='flex align-items-center mb-3'>
                  <RadioButton inputId='increase' value='increase' onChange={(e) => setOption(e.value)} checked={option === 'increase'} />
                  <label htmlFor='increase' className="ml-2">Aumentar</label>
                </div>
                <div className='flex align-items-center'>
                  <RadioButton inputId='decrease' value='decrease' onChange={(e) => setOption(e.value)} checked={option === 'decrease'} />
                  <label htmlFor='decrease' className='ml-2'>Disminuir</label>
                </div>
              </div>
              <Button label='Actualizar' onClick={handleUpdatePrices} className='w-auto lg:w-full' size='small'></Button>
            </div>
          </div>

          <Link to={`/productos`}>
            <Card className='!shadow-none border hidden lg:block'>
              <div className='flex items-center gap-3'>
                <ArrowLeft size={16} className='text-blue-500' />
                <span className='text-sm font-medium'>Volver a productos</span>
              </div>
            </Card>
          </Link>
        </div>

        <div className='md:w-3/4 xl:w-4/5 2xl:w-5/6'>
          <div className='panel'>
            <div className='panel-body grid grid-cols-2 md:grid-cols-4 gap-3'>
              <div className='w-full'>
                <label className='block font-medium mb-1'>Proveedor</label>
                <Dropdown value={provider} options={listProviders} onChange={onDropdownChange}
                  name='provider' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
              </div>
              <div className='w-full'>
                <label className='block font-medium mb-1'>Rubro</label>
                <Dropdown value={category} options={listCategories} onChange={onDropdownChange}
                  name='category' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
              </div>
              <div className='w-full'>
                <label className='block font-medium mb-1'>Marca</label>
                <Dropdown value={brand} options={listBrands} onChange={onDropdownChange}
                  name='brand' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
              </div>
              <div className='w-full'>
                <label className='block font-medium mb-1'>Cantidad</label>
                <Dropdown value={recordsQuantity} options={listQuantities} onChange={onDropdownChange}
                  name='recordsQuantity' className='p-inputtext-sm w-full' />
              </div>
            </div>
          </div>
          <div className='panel'>
            <div className='panel-body'>
              <DataTable value={listProducts} tableStyle={{ minWidth: '70rem' }} scrollable scrollHeight='480px' selectionMode={'checkbox'} size='small'
                selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} dataKey="id"
                emptyMessage="No se encontraron productos" stripedRows>
                <Column selectionMode='multiple' style={{ width: '5%' }} />
                <Column field='code' header='Código' style={{ width: '10%' }} />
                <Column field='description' header='Descripción' style={{ width: '40%' }} />
                <Column field={(rowData) => formatCurrency(rowData.cashPrice)} header='Efectivo' style={{ width: '10%' }} />
                <Column field={(rowData) => formatCurrency(rowData.debitPrice)} header='Débito' style={{ width: '10%' }} />
                <Column field={(rowData) => formatCurrency(rowData.creditPrice)} header='Crédito' style={{ width: '10%' }} />
                <Column field='lastPrice' header='Ult. Precio' style={{ width: '15%' }}
                  body={(rowData) => rowData.lastPrice ? formatDateTime(rowData.lastPrice) : ''} />
                <Column style={{ width: 'auto' }}
                  body={(rowData) => (
                    <Link to={`/producto/detalle/${rowData.id}`} target='_blank'>
                      <Button severity='primary' size='small'>
                        <i className='bi bi-eye-fill'></i>
                      </Button>
                    </Link>
                  )}>
                </Column>
              </DataTable>
              <Paginator first={first} rows={recordsQuantity} totalRecords={totalElements}
                onPageChange={onPageChange} pageLinkSize={3} className='mt-3 !p-0' />
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}