import React from 'react';
import ExcelJS from 'exceljs';

import { FileDown } from 'lucide-react';
import { formatCurrency } from '../helper/format';
import { Button } from 'primereact/button';

const ExportStocksToExcel = ({ stocks, shop }) => {

  const handleExport = async () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Stock-${shop}`);

    // Set landscape orientation
    worksheet.pageSetup.orientation = 'landscape';

    // Format the prices in a new array
    const formattedProducts = stocks.map(stock => ({
      ...stock,
      code: stock.product.code,
      description: stock.product.description,
      priceEffective: formatCurrency(stock.product.cashPrice),
      priceDebit: formatCurrency(stock.product.debitPrice),
      priceCredit: formatCurrency(stock.product.creditPrice),
    }))

    // Define the columns
    worksheet.columns = [
      { header: 'Código', key: 'code', width: 15 },
      { header: 'Descripción', key: 'description', width: 65 },
      { header: 'Efectivo', key: 'priceEffective', width: 10 },
      { header: 'Débito', key: 'priceDebit', width: 10 },
      { header: 'Crédito', key: 'priceCredit', width: 10 },
      { header: 'Stock', key: 'quantity', width: 10 },
    ];

    // Add data to the worksheet
    worksheet.addRows(formattedProducts);

    // Customize the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'left' };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 14 };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
    })

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob and download link
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `Stock-${shop}.xlsx`;
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  return (
    <Button label='Exportar excel' onClick={handleExport} icon='bi bi-download' severity='secondary' />
  )

}

export default ExportStocksToExcel;