import React, { useEffect, useState } from 'react';

import SockJS from 'sockjs-client';

import { Client } from '@stomp/stompjs';
import { NavLink } from 'react-router-dom';
import { Button } from 'primereact/button';

import { axiosInstance, BASE_URL } from '../../helper/axion.helper';

const NotificationBadge = () => {
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        const fetchUnreadCount = async () => {
            try {
                const response = await axiosInstance.get('/api/v1/notification/unread/count');
                setUnreadCount(response.data);
            } catch (error) {
                console.error('Error fetching unread notifications:', error);
            }
        };

        fetchUnreadCount();

        const socket = new SockJS(BASE_URL + '/ws');
        const client = new Client({
            webSocketFactory: () => socket,
            onConnect: () => {
                client.subscribe('/topic/notifications', message => {
                    if (message.body) {
                        setUnreadCount(Number(message.body));
                    }
                });
            },
            onStompError: (frame) => {
                console.error('STOMP error', frame);
            },
        });

        client.activate();

        return () => {
            client.deactivate();
        };
    }, []);

    return (
        <NavLink className='block text-decoration-none' to={'/notificaciones'}>
            <Button label='Notificaciones' icon='pi pi-bell' badge={unreadCount} badgeClassName='p-badge-info' outlined />
        </NavLink>
    );
};

export default NotificationBadge;