import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import { InputNumber } from 'primereact/inputnumber'

import { usePagination } from '../../hooks/use.pagination'

import ProductFilters from '../../utils/filters.product'
import ProductService from '../../services/product.service'

const productService = new ProductService()

export const MassiveUpdatePage = () => {

  const initialPagination = {
    page: 0,
    brand: 'TODOS',
    category: 'TODOS',
    provider: 'TODOS',
    recordsQuantity: 10
  }

  const [first, setFirst] = useState(0)
  const [listProducts, setListProducts] = useState([])
  const [totalElements, setTotalElements] = useState(null)

  const { paginationState, onDropdownChange, setPaginationState } = usePagination(initialPagination)
  const { provider, category, brand, recordsQuantity } = paginationState

  const { listProviders, listCategories, listBrands, listQuantities } = ProductFilters();

  useEffect(() => {
    loadProducts()
  }, [paginationState])

  const loadProducts = () => {
    productService.getAll(paginationState).then(data => {
      setListProducts(data.content)
      setTotalElements(data.totalElements)
    })
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const onInputTextChange = (rowData, name) => (e) => {
    const { value } = e.target

    setListProducts(prevList =>
      prevList.map(item => item.code === rowData.code ? { ...item, [name]: value } : item)
    )
  }

  const onInputNumberChange = (rowData, name) => (e) => {
    const { value } = e

    setListProducts(prevList =>
      prevList.map(item => item.code === rowData.code ? { ...item, [name]: value } : item)
    )
  }

  const handleUpdateProducts = () => {
    productService.updateProducts(listProducts)
      .then((data) => {
        loadProducts()
        Swal.fire('Actualizado', 'Se han actualizado los productos con éxito.', 'success')
      })
      .catch((error) => {
        Swal.fire('Error', 'Hubo un problema al intentar actualizar los productos.<br> Por favor, inténtelo de nuevo más tarde.', 'error')
      })
  }

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Modificación masiva de productos</h2>
        <span className='text-sm'>Todos los campos son editables. Para guardar los cambios realizados presione el botón al final de la tabla "Guardar cambios"</span>
      </div>

      <div className='panel'>
        <div className='panel-body grid grid-cols-2 md:grid-cols-4 gap-3'>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Proveedor</label>
            <Dropdown value={provider} options={listProviders} onChange={onDropdownChange}
              name='provider' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Rubro</label>
            <Dropdown value={category} options={listCategories} onChange={onDropdownChange}
              name='category' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Marca</label>
            <Dropdown value={brand} options={listBrands} onChange={onDropdownChange}
              name='brand' className='p-inputtext-sm w-full' emptyFilterMessage='Sin resultados' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Cantidad</label>
            <Dropdown value={recordsQuantity} options={listQuantities} onChange={onDropdownChange}
              name='recordsQuantity' className='p-inputtext-sm w-full' />
          </div>
        </div>
      </div>

      <div className='panel'>
        <div className='panel-body'>
          <DataTable value={listProducts} tableStyle={{ minWidth: '70rem' }} scrollable scrollHeight='440px' editMode="cell" size='small'
            emptyMessage='No se encontraron resultados' stripedRows>
            <Column field="code" header="Código" style={{ width: '10%' }} />

            <Column field="description" header="Descripción" style={{ width: '60%' }} body={(rowData) => (
              <InputText type="text" className='p-inputtext-sm w-full'
                name='description' value={rowData.description} onChange={onInputTextChange(rowData, 'description')} />
            )}>
            </Column>

            <Column field="cashPrice" header="Efectivo" style={{ width: '10%' }} body={(rowData) => (
              <InputNumber mode='currency' currency='ARS' locale='es-AR' className='w-full'
                minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                name='cashPrice' value={rowData.cashPrice} onChange={onInputNumberChange(rowData, 'cashPrice')} />
            )}>
            </Column>

            <Column field="debitPrice" header="Débito" style={{ width: '10%' }} body={(rowData) => (
              <InputNumber mode='currency' currency='ARS' locale='es-AR' className='w-full'
                minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                name='debitPrice' value={rowData.debitPrice} onChange={onInputNumberChange(rowData, 'debitPrice')} />
            )}>
            </Column>

            <Column field="creditPrice" header="Crédito" style={{ width: '10%' }} body={(rowData) => (
              <InputNumber mode='currency' currency='ARS' locale='es-AR' className='w-full'
                minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm w-full'
                name='creditPrice' value={rowData.creditPrice} onChange={onInputNumberChange(rowData, 'creditPrice')} />
            )}>
            </Column>

            <Column style={{ width: 'auto' }}
              body={(rowData) => (
                <Link to={`/producto/detalle/${rowData.id}`}>
                  <Button severity='primary' size='small'>
                    <i className='bi bi-eye-fill'></i>
                  </Button>
                </Link>
              )}>
            </Column>
          </DataTable>
          <Paginator first={first} rows={recordsQuantity} totalRecords={totalElements}
            onPageChange={onPageChange} pageLinkSize={1} className='mt-5 !p-0' />
        </div>
      </div>

      <div className='flex justify-end'>
        <div>
          <Button label='Guardar cambios' onClick={handleUpdateProducts} className='w-full' size='small' />
        </div>
      </div>
    </div>
  )
}