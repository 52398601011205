import { useContext, useState } from 'react'

import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import { InputText } from 'primereact/inputtext'
import { useNavigate, NavLink, Link } from 'react-router-dom'

import { HomeIcon, LayoutDashboard, ShoppingCart, Package, Clipboard, MessageSquare, Bell, Activity, User, Info, ClipboardList } from 'lucide-react'

import UserContext from '../../user.context'
import removeCookie from '../../hooks/remove.cookie'
import NotificationBadge from './notification.badge'

export default function Header() {

  const [visible, setVisible] = useState(false)
  const [user, setUser] = useContext(UserContext)
  const [searchValue, setSearchValue] = useState('')

  const navigate = useNavigate()

  const logOut = () => {
    setUser(null)
    removeCookie('jwt_authorization')
  }

  const redirectToSearch = () => {
    setVisible(false)

    if (searchValue === '') {
      return
    }

    const encodedValue = encodeURIComponent(searchValue)
    navigate(`/producto/buscar/${encodedValue}`)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setVisible(false)
      redirectToSearch()
    }
  }

  return (
    <nav className='bg-white w-full border-b pl-[20px] pr-[30px]  py-[10px]'>
      <div className="hidden lg:flex justify-between">
        <div className="flex items-center gap-3 w-1/2">
          {user.rol !== 'ROLE_BUSCADOR' &&
            <div className='2xl:hidden'>
              <Button icon='bi bi-list' onClick={() => setVisible(true)} />
            </div>
          }

          <Link to={`/inicio`}>
            <span className="text-xl text-blue-500 font-bold">GUSTOCK</span>
          </Link>

          <InputText value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={handleKeyPress} type='text'
            className='p-inputtext-sm !pl-3 w-64 xl:w-96' placeholder='Ingrese código, código de barras o descripción' />
          <Button label='Buscar' onClick={redirectToSearch} size='small' />
        </div>

        <div className='flex justify-end items-center gap-3 w-1/2'>
          {user.rol !== 'ROLE_BUSCADOR' ?
            <div className="flex justify-end items-center gap-3">
              <NavLink className='block text-decoration-none' to={'/mensajeria'} onClick={() => setVisible(false)}>
                <Button label='Mensajería' icon='pi pi-comment' outlined />
              </NavLink>
              <NotificationBadge />
              <Button onClick={logOut} label="Cerrar sesión" size="small" className='!rounded !h-[32px]' />
            </div>
            :
            <div>
              <Button onClick={logOut} label="Salir" size="small" className='!rounded !h-[32px]' />
            </div>
          }
        </div>
      </div>

      <div className="flex gap-3 items-center lg:hidden">
        {user.rol !== 'ROLE_BUSCADOR'
          ? <Button icon='bi bi-list' onClick={() => setVisible(true)} />
          : <Button onClick={logOut} label="Salir" size="small" />
        }
        <Link to={`/inicio`}>
          <span className="text-xl text-blue-500 font-bold">GUSTOCK</span>
        </Link>
        <InputText value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={handleKeyPress} type='text'
          className="!p-inputtext-sm w-[260px]" placeholder='Ingrese código, código de barras o descripción' />
        <Button icon='bi bi-search' onClick={redirectToSearch} size="small" />
      </div>

      <Sidebar visible={visible} onHide={() => setVisible(false)} className='!bg-[#2A499B] !w-[210px]'>
        <div className='text-white text-[14px] px-2'>
          <NavLink className='block text-decoration-none' to={'/inicio'} onClick={() => setVisible(false)}>
            <div className='flex items-center gap-3 py-2 my-1'>
              <HomeIcon size={20} className='text-white' />
              <span className='font-medium'>Inicio</span>
            </div>
          </NavLink>

          {user.rol === 'ROLE_ADMINISTRADOR' && (
            <div>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/panel'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <LayoutDashboard size={20} className='text-white' />
                  <span className='font-medium'>Panel</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/personalizados'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <ClipboardList size={20} className='text-white' />
                  <span className='font-medium'>Personalizados</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/venta/historial'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <ShoppingCart size={20} className='text-white' />
                  <span className='font-medium'>Ventas</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/clientes'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <User size={20} className='text-white' />
                  <span className='font-medium'>Clientes</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/productos'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <Package size={20} className='text-white' />
                  <span className='font-medium'>Productos</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/reposiciones'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <Clipboard size={20} className='text-white' />
                  <span className='font-medium'>Reposiciones</span>
                </div>
              </NavLink>
              <NavLink className='block text-decoration-none py-2 my-1' to={'/movimientos'} onClick={() => setVisible(false)}>
                <div className='flex items-center gap-3'>
                  <Activity size={20} className='text-white' />
                  <span className='font-medium'>Movimientos</span>
                </div>
              </NavLink>
            </div>
          )}

          <NavLink className='block text-decoration-none py-2 my-1' to={'/mensajeria'} onClick={() => setVisible(false)}>
            <div className='flex items-center gap-3'>
              <MessageSquare size={20} className='text-white' />
              <span className='font-medium'>Mensajería</span>
            </div>
          </NavLink>
          <NavLink className='block text-decoration-none py-2 my-1' to={'/notificaciones'} onClick={() => setVisible(false)}>
            <div className='flex items-center gap-3'>
              <Bell size={20} className='text-white' />
              <span className='font-medium'>Notificaciones</span>
            </div>
          </NavLink>

          {user.rol === 'ROLE_ADMINISTRADOR' && (
            <NavLink className='block text-decoration-none py-2 my-1' to={'/novedades'} onClick={() => setVisible(false)}>
              <div className='flex items-center gap-3'>
                <Info size={20} className='text-white' />
                <span className='font-medium'>Novedades</span>
              </div>
            </NavLink>
          )}

          <div className='lg:hidden mt-5'>
            <Button onClick={logOut} label='Cerrar sesión' size='small' outlined className='w-full !bg-white !border-white' />
          </div>
        </div>
      </Sidebar>
    </nav >
  )
}
