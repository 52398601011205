import axios from "axios";
import { BASE_URL } from "../helper/axion.helper";

class RestockService {

  baseUrl = BASE_URL + "/api/v1/restock";

  create(restock) { return axios.post(this.baseUrl + "/createRestock", restock).then(res => res.data) }

  getAll() { return axios.get(this.baseUrl + "/getAllRestocks").then(res => res.data) }

  getById(id) { return axios.get(this.baseUrl + "/" + id).then(res => res.data) }

  /*
  update(provider) { return axios.put(this.baseUrl + "/updateProvider", provider).then(res => res.data) }

  delete(id) { return axios.delete(this.baseUrl + "/deleteProvider/" + id).then(res => res.data) }

  getAll() { return axios.get(this.baseUrl + "/getAllProviders").then(res => res.data) }
*/
}

export default RestockService;