import axios from "axios";

import { builderParams } from "../helper/builder.params";
import { BASE_URL } from "../helper/axion.helper";

const baseUrl = BASE_URL + "/api/v1/sale";

class SaleService {

    getById(id) { return axios.get(baseUrl + "/" + id).then(res => res.data) }

    getLastByUserAndShop(user, shop) { return axios.get(baseUrl + "/getLastSaleByUser?user=" + user + "&&shop=" + shop).then(res => res.data) }

    create(sale) { return axios.post(baseUrl + "/create", sale).then(res => res.data) }

    delete(id) { return axios.delete(baseUrl + "/delete/" + id).then(res => res.data) }

    getAll(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/list?" + params).then(res => res.data)
    }

    getAllByCategory(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/list/category?" + params).then(res => res.data)
    }

    getAllByProduct(request) {
        const params = builderParams(request)
        return axios.get(baseUrl + "/list/product?" + params).then(res => res.data)
    }

    getAllCurrentByShop(shopName) {
        return axios.get(baseUrl + "/getAllCurrentSalesByShop/" + shopName).then(res => res.data)
    }

}

export default SaleService;