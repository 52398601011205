import React, { useEffect, useState } from 'react'

import { Button } from 'primereact/button'
import { TabMenu } from 'primereact/tabmenu'
import { Link, useParams } from 'react-router-dom'

import ShopService from '../../services/shop.service'
import SalesComponent from './components/sales.component'
import ProductsComponent from './components/products.component'
import { ShoppingCart } from 'lucide-react'

const shopService = new ShopService()

export const ShopPage = () => {

  const { name } = useParams()
  const [shop, setShop] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  const items = [
    { label: 'Productos', icon: 'bi bi-box' },
    { label: 'Ventas', icon: 'bi bi-cart' },
    { label: 'Turnos', icon: 'bi bi-clock' },
    { label: 'Caja', icon: 'bi bi-currency-dollar' },
  ]

  useEffect(() => {
    shopService.getByName(name).then(data => {
      setShop(data)
    })
  }, []);

  return (
    <div>
      <div className='md:flex gap-5'>
        <div className='md:w-1/3 lg:w-1/5 2xl:1/6'>

          <div className='panel'>
            <div className='panel-body'>
              <p className='text-2xl text-blue-900 mb-1'>{shop.type === 'LOCAL' ? 'Local' : 'Depósito'} {shop.name}</p>
              <p className='text-sm'>Dirección: {shop.address}</p>
            </div>
          </div>

          {shop.type === 'LOCAL' &&
            <div className='flex gap-5 md:block'>
              <Link to={`/local/${name}/venta/registrar`}>
                <div className='bg-white flex gap-3 items-center justify-around border rounded text-center hover:cursor-pointer p-3 mb-5'>
                  <i class='bi bi-cart-plus text-[#2A499B] text-[35px]'></i>
                  <span className='block text-sm font-medium'>NUEVA VENTA</span>
                </div>
              </Link>

              <Link to={`/local/${name}/devolucion/registrar`}>
                <div className='bg-white flex gap-3 items-center justify-around border rounded text-center hover:cursor-pointer p-3'>
                  <i class='bi bi-cart-dash text-[#2A499B] text-[35px]'></i>
                  <span className='block text-sm font-medium'>DEVOLUCIÓN</span>
                </div>
              </Link>
            </div>
          }
        </div>

        <div className='md:w-2/3 lg:w-4/5 2xl:w-full'>
          <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='border-x border-t rounded mb-5' />
          {activeIndex === 1 && <SalesComponent shop={name} />}
          {activeIndex === 0 && <ProductsComponent shop={name} />}
        </div>
      </div >
    </div >
  )
}

