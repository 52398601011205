import axios from "axios";
import { BASE_URL } from "../helper/axion.helper";

const baseUrl = BASE_URL + "/api/v1/file";

class FileService {

    getPdf(dateTime) { return axios.get(baseUrl + "/downloadPdf?dateTime=" + dateTime).then(res => res) }

    getProductImage(imageName) { return axios.get(baseUrl + "/getProductImage/" + imageName).then(res => res.data) }

}

export default FileService;