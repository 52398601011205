import { useState, useEffect } from 'react'

import ShopService from '../services/shop.service'
import CategoryService from '../services/category.service'

const shopService = new ShopService()
const categoryService = new CategoryService()

function SaleFilters() {

  const [shops, setShops] = useState([])
  const [categories, setCategories] = useState([])
  const [quantities] = useState([10, 20, 30, 40, 50, "TODOS"])
  const [payments] = useState(['TODOS', "EFECTIVO", "DEBITO", "CREDITO", "CODIGOQR"])

  useEffect(() => {
    const fetchLocales = shopService.getAllByTypeShop().then((res) => {
      const newList = res.map((shop) => shop.name)
      newList.unshift('TODOS')
      setShops(newList);
    });

    const fetchRubros = categoryService.getAll().then((res) => {
      const newList = res.map((res) => res.description)
      setCategories(newList);
    });

    Promise.all([fetchLocales, fetchRubros])
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return { shops, payments, categories, quantities};
}

export default SaleFilters;
