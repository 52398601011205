import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { SelectButton } from 'primereact/selectbutton'


import SettingsService from '../../../services/settings.service'

const settingsService = new SettingsService

export const SettingsComponent = () => {

  const [config, setConfig] = useState({
    timeFrom: '',
    timeUntil: ''
  })

  const options = [
    { label: 'Activado', value: true },
    { label: 'Desactivado', value: false }
  ];

  useEffect(() => {
    settingsService.getConfig().then((data) => {
      setConfig(data)
      console.log(data)
    })
  }, [])

  const onInputChange = ({ target }) => {
    const { name, value } = target
    setConfig({
      ...config,
      [name]: value
    })
  }

  const handleUpdateSettings = () => {
    console.log(config)
    settingsService.updateSettings(config)
      .then(() => {
        Swal.fire('Actualizado', 'Los cambios fueron registrados con éxito.', 'success')
      })
      .catch(() => {
        Swal.fire('Error', 'Ocurrió un error al intentar actualizar los datos.', 'error')
      })
  }

  return (
    <div>
      <div className='grid grid-cols-1 2xl:grid-cols-2 gap-5 mb-5'>
        <Card className='!shadow-none border'>
          <h3 className="text-lg font-medium mb-2">Recargo por tarjeta</h3>
          <p>Se aplicará automáticamente sobre el precio de contado al momento de registrar o modificar un producto</p>

          <div className="w-full mt-5">
            <label className='block font-bold mb-2'>Porcetanje</label>
            <InputNumber value={config.surcharge} onValueChange={onInputChange}
              name='surcharge' className="p-inputtext-sm" suffix="%" />
          </div>
        </Card>

        <Card className='!shadow-none border'>
          <h3 className="text-lg font-medium mb-2">Redondeo de precios</h3>
          <p>Los cambios se verán reflejados la próxima vez que se actualicen los precios utilizando la herramienta 'Actualización rápida'</p>

          <div className='mt-5'>
            <label className='block font-bold mb-2'>Estado</label>
            <SelectButton value={config.rounded} onChange={onInputChange} name='rounded' options={options} optionLabel='' />
          </div>
        </Card>

        <Card className='!shadow-none border'>
          <h3 className="text-lg font-medium mb-2">Horario de ingreso permitido</h3>
          <p>Los usuarios que no sean administradores solo podrán acceder al sistema dentro del horario establecido</p>

          <div className='w-full flex gap-5 mt-5'>
            <div className='w-[182px] h-[44]'>
              <label htmlFor="timeFrom" className='font-bold block mb-2'>Desde</label>
              <input type='time' className='border w-full rounded-md p-2' name='timeFrom'
                value={config.timeFrom} onChange={onInputChange} />
            </div>
            <div className='w-[182px] h-[44]'>
              <label htmlFor='timeUntil' className='font-bold block mb-2'>Hasta</label>
              <input type='time' className='border w-full rounded-md p-2' name='timeUntil'
                value={config.timeUntil} onChange={onInputChange} />
            </div>
          </div>
        </Card>

        <Card className='!shadow-none border'>
          <h3 className="text-lg font-medium mb-2">Margen de precios</h3>
          <p>Se te notificará si el precio de un producto difiere al registrado en una venta si este supera el monto permitido.</p>

          <div className="w-full mt-5">
            <label className='block font-bold mb-2'>Permitido</label>
            <InputNumber mode='currency' currency='ARS' locale='es-AR'
              minFractionDigits={0} maxFractionDigits={0} inputClassName='p-inputtext-sm'
              name='difference' value={config.difference} onValueChange={onInputChange} placeholder='$ 0' />
          </div>
        </Card>
      </div>

      <div className='text-end'>
        <Button onClick={handleUpdateSettings} label='Guardar cambios' size="small"></Button>
      </div>
    </div>
  )
}