import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Card } from 'primereact/card'
import { Link } from 'react-router-dom'
import { Database, PackagePlus } from 'lucide-react'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'

import { addLocale } from 'primereact/api'
import { usePagination } from '../../../hooks/use.pagination'
import { formatDate, formatCurrency } from '../../../helper/format'
import { regionalCalendar } from '../../../helper/regional.calendar'

import StockService from '../../../services/stock.service'
import ProductFilters from '../../../utils/filters.product'
import ExportStocksToExcel from '../../../utils/export.stocks.component'
import { Button } from 'primereact/button'

const stockService = new StockService()

const ProductsComponent = ({ shop }) => {

  const initialPagination = {
    page: 0,
    brand: 'TODOS',
    category: 'TODOS',
    provider: 'TODOS',
    orderByPrice: null,
    orderByStock: null,
    recordsQuantity: 10
  }

  const orderStock = [
    { value: null, description: 'Sin orden' },
    { value: 'desc', description: 'Mayor a menor' },
    { value: 'asc', description: 'Menor a mayor' },
  ]

  const orderPrice = [
    { value: null, description: 'Sin orden' },
    { value: 'desc', description: 'Mas reciente' },
    { value: 'asc', description: 'Mas antiguo' },
  ]

  const [first, setFirst] = useState(0)
  const [totalStock, setTotalStock] = useState(0)
  const [listProducts, setListProducts] = useState([])
  const [totalElements, setTotalElements] = useState(null)

  const { paginationState, onDropdownChange, setPaginationState } = usePagination(initialPagination)
  const { provider, category, brand, recordsQuantity, orderByStock, orderByPrice } = paginationState
  const { listProviders, listCategories, listBrands, listQuantities } = ProductFilters()

  addLocale('es', regionalCalendar)

  useEffect(() => {
    loadProducts()
  }, [paginationState]);

  const loadProducts = () => {
    stockService.getAllByShop(shop, paginationState).then(data => {
      setListProducts(data.content)
      setTotalElements(data.totalElements)

      const totalStock = calculateTotalStock(data.content);
      setTotalStock(totalStock);
    })
  }

  const calculateTotalStock = (items) => {
    return items.reduce((total, item) => total + item.quantity, 0);
  }

  const onPageChange = (event) => {
    setFirst(event.first)
    setPaginationState(prevState => ({ ...prevState, page: event.page }))
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el registro del producto de este local',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        stockService.delete(id)
          .then((data) => {
            loadProducts()
            Swal.fire('Eliminado', '"' + data + '" ha sido eliminado del local.', 'success')
          })
          .catch(() => {
            Swal.fire('Error', 'Hubo un problema al eliminar el producto. Por favor, inténtalo de nuevo más tarde.', 'error')
          });
      }
    })
  }

  const header = <div className='text-end '><span className='text-sm font-medium'>Total de stock: {totalStock} unidades</span></div>

  return (
    <div>
      <div className='panel'>
        <div className='panel-body grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-3'>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Proveedor</label>
            <Dropdown value={provider} options={listProviders} onChange={onDropdownChange}
              name='provider' emptyFilterMessage="Sin resultados" className='p-inputtext-sm w-full' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Rubro</label>
            <Dropdown value={category} options={listCategories} onChange={onDropdownChange}
              name='category' emptyFilterMessage="Sin resultados" className='p-inputtext-sm w-full' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Marca</label>
            <Dropdown value={brand} options={listBrands} onChange={onDropdownChange}
              name='brand' emptyFilterMessage="Sin resultados" className='p-inputtext-sm w-full' filter />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Stock</label>
            <Dropdown value={orderByStock} options={orderStock} onChange={onDropdownChange}
              name='orderByStock' optionLabel='description'
              placeholder='Selecciona un orden' className='p-inputtext-sm w-full' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Ult. Precio</label>
            <Dropdown value={orderByPrice} options={orderPrice} onChange={onDropdownChange}
              name='orderByPrice' optionLabel='description'
              placeholder='Selecciona un orden' className='p-inputtext-sm w-full' />
          </div>
          <div className='w-full'>
            <label className='block font-medium mb-1'>Cantidad</label>
            <Dropdown value={recordsQuantity} options={listQuantities} onChange={onDropdownChange}
              name='recordsQuantity' className='p-inputtext-sm w-full' />
          </div>
        </div>
      </div>

      <div className='panel'>
        <div className='panel-body'>
          <div className='mb-3'>
            <div className='flex flex-wrap items-center gap-3'>
              <Link to={`/producto/registrar`}>
                <Button label='Agregar producto' icon='bi bi-plus-circle' severity='primary' />
              </Link>
              <ExportStocksToExcel stocks={listProducts} shop={shop} />
            </div>
          </div>
          <DataTable value={listProducts} tableStyle={{ minWidth: '80rem' }} scrollable scrollHeight='440px'
            header={header} stripedRows emptyMessage='No se encontraron resultados' size='small'>
            <Column field='product.code' header='Código' style={{ width: '10%' }} />
            <Column field='product.description' header='Descripción' style={{ width: '40%' }} />
            <Column field={(rowData) => formatCurrency(rowData.product.cashPrice)} header='Efectivo' style={{ width: 'auto' }} />
            <Column field={(rowData) => formatCurrency(rowData.product.debitPrice)} header='Débito' style={{ width: 'auto' }} />
            <Column field={(rowData) => formatCurrency(rowData.product.creditPrice)} header='Crédito' style={{ width: 'auto' }} />
            <Column field={(rowData) => rowData.product.lastPrice ? formatDate(rowData.product.lastPrice) : '-'} header='U. Precio' style={{ width: 'auto' }} />
            <Column field={(rowData) => formatDate(rowData.lastUpdate)} header='U. Stock' style={{ width: 'auto' }} />
            <Column field='quantity' header='Stock' style={{ width: '5%' }} />
            <Column header='Acciones' style={{ width: '5%' }}
              body={(rowData) => (
                <div className='flex gap-1'>
                  <Link to={`/producto/detalle/${rowData.product.id}`} target='_blank'>
                    <Button className='!rounded' severity='primary' size='small'>
                      <i className='bi bi-eye-fill'></i>
                    </Button>
                  </Link>
                  <Link to={`/producto/modificar/${rowData.product.id}`} target='_blank'>
                    <Button className='!rounded !text-white' severity='warning' size='small'>
                      <i className='bi bi-pencil-fill'></i>
                    </Button>
                  </Link>
                  <Button className='!rounded' severity='danger' size='small'
                    onClick={() => handleDelete(rowData.idStock)} >
                    <i className='bi bi-trash-fill'></i>
                  </Button>
                </div>
              )}>
            </Column>
          </DataTable>
          <Paginator first={first} rows={recordsQuantity} pageLinkSize={1} totalRecords={totalElements}
            onPageChange={onPageChange} className='mt-5 !p-0' />
        </div>
      </div>
    </div>
  )
}

export default ProductsComponent;