import { useEffect, useState } from 'react'

import { Tag } from 'primereact/tag'

import { formatDateTime } from '../../helper/format'

import RestockService from '../../services/restock.service'
import { Link } from 'react-router-dom'

const restockService = new RestockService()

export const RestockListPage = () => {

  const [listRestock, setListRestock] = useState([])

  useEffect(() => {
    restockService.getAll().then(data => {
      setListRestock(data)
      console.log(data)
    })
  }, [])

  return (
    <div>
      <div className='pb-[15px]'>
        <h2 className='text-2xl mb-1'>Reposiciones</h2>
        <span className='text-sm'>Gestioná la reposición de tus productos en tus sucursales y visualiza un registro histórico de todos los movimientos.</span>
      </div>

      <div className='grid grid-cols-5 gap-3'>
        {listRestock.map((data) => {
          return (
            <Link to={`/reposiciones/solicitud/${data.id}`} key={data.id}>
              <div className='panel h-[210px] cursor-pointer hover:shadow-lg'>
                <div className='panel-heading'>
                  <span className='font-medium'>Solicitud N°{data.id}</span>
                  <span>{formatDateTime(data.dateTime)}</span>
                </div>
                <div className='panel-body h-[170px]'>
                  <div className='flex flex-col gap-2 text-sm'>
                    <div className='flex justify-between'>
                      <span className='font-medium block'>Realiza:</span>
                      <p>{data.author}</p>
                    </div>

                    <div className='flex justify-between'>
                      <span className='font-medium'>Total de unidades:</span>
                      <p>{data.quantity} registradas</p>
                    </div>

                    <div className='grid grid-cols-2'>
                      <span className='font-medium block'>Personal asignado:</span>
                      <div className='flex flex-wrap justify-end'>
                        {data.assignedUsers.map((username) => {
                          return (
                            <p key={username}>{username},&nbsp;</p>
                          );
                        })}
                      </div>
                    </div>

                    <div className='flex justify-between'>
                      <span className='font-medium block'>Estado:</span>
                      <div>
                        {data.state === 'CANCELADO' && <Tag severity='danger' value='Cancelado' className='!rounded' />}
                        {data.state === 'PENDIENTE' && <Tag severity='warning' value='Pendiente' className='!rounded' />}
                        {data.state === 'COMPLETADO' && <Tag severity='success' value='Completado' className='!rounded' />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}

      </div>
    </div >
  )
}