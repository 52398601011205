import React, { useContext } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import UserContext from '../user.context'
import RequireAuth from '../utils/require.auth'

import { ConstructionPage } from '../pages/construction.page'
import { NewsPage } from '../pages/news.page'
import { UserPage } from '../pages/user.page'
import { AdminPage } from '../pages/admin.page'
import { RestockListPage } from '../pages/restock/restock.list.page'
import { SearchPage } from '../pages/search.page'
import { ShopPage } from '../pages/shop/shop.page'
import { ChatPage } from '../pages/chat/chat.page'
import { RestockCreatePage } from '../pages/restock/restock.create.page'
import { CostumersPage } from '../pages/costumers.page'
import { ProductsPage } from '../pages/product/products.page'
import { NotificationsPage } from '../pages/notifications.page'
import { SaleDetailPage } from '../pages/sale/sale.detail.page'
import { SaleCreatePage } from '../pages/sale/sale.create.page'
import { SaleChangePage } from '../pages/sale/sale.change.page'
import { SaleHistoryPage } from '../pages/sale/sale.history.page'
import { DashboardPage } from '../pages/dashboard/dashboard.page'
import { QuickUpdatePage } from '../pages/product/update.quick.page'
import { ProductDetailPage } from '../pages/product/product.detail.page'
import { ProductCreatePage } from '../pages/product/product.create.page'
import { ProductUpdatePage } from '../pages/product/product.update.page'
import { ProductsStatePage } from '../pages/product/products.state.page'
import { MassiveUpdatePage } from '../pages/product/update.massive.page'
import { SaleHistoryByProductPage } from '../pages/sale/sale.history.product.page'
import { SaleHistoryByCategoryPage } from '../pages/sale/sale.history.category.page'
import { RestockCheckPage } from '../pages/restock/restock.check.page'
import { CostumListPage } from '../pages/custom.list.page'

export const AppRouter = () => {

  const [user] = useContext(UserContext)

  const roleComponentMap = {
    ROLE_ADMINISTRADOR: <AdminPage />,
    ROLE_BUSCADOR: <SearchPage />,
    ROLE_USUARIO: <UserPage />,
  }

  const getComponentForRole = (role) => {
    return roleComponentMap[role];
  }

  return (
    <Routes>
      <Route exact path="/" element={getComponentForRole(user.rol)} />
      <Route exact path="/inicio" element={getComponentForRole(user.rol)} />

      <Route element={<RequireAuth allowedRoles={['ROLE_USUARIO', 'ROLE_BUSCADOR', 'ROLE_ADMINISTRADOR']} />}>
        <Route exact path="/producto/buscar/:criteria" element={<SearchPage />} />
        <Route exact path="/producto/detalle/:id" element={<ProductDetailPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={['ROLE_USUARIO', 'ROLE_ADMINISTRADOR']} />}>
        <Route exact path="/mensajeria" element={<ChatPage />} />
        <Route exact path="/notificaciones" element={<NotificationsPage />} />
        <Route exact path="/local/:name/venta/registrar" element={<SaleCreatePage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={['ROLE_ADMINISTRADOR']} />}>
        <Route exact path="/novedades" element={<NewsPage />} />
        <Route exact path="/personalizados" element={<CostumListPage />} />
        <Route exact path="/reposiciones" element={<RestockListPage />} />
        <Route exact path="/reposiciones/solicitud/:id" element={<RestockCheckPage />} />
        <Route exact path="/panel" element={<DashboardPage />} />
        <Route exact path="/local/:name" element={<ShopPage />} />
        <Route exact path="/clientes" element={<CostumersPage />} />
        <Route exact path="/productos" element={<ProductsPage />} />
        <Route exact path="/deposito/:name" element={<ShopPage />} />
        <Route exact path="/movimientos" element={<RestockCreatePage />} />
        <Route exact path="/venta/historial" element={<SaleHistoryPage />} />
        <Route exact path="/venta/detalle/:id" element={<SaleDetailPage />} />
        <Route exact path="/productos/:state" element={<ProductsStatePage />} />
        <Route exact path="/devolucion/detalle/:id" element={<SaleDetailPage />} />
        <Route exact path="/producto/modificar/:id" element={<ProductUpdatePage />} />
        <Route exact path="/producto/registrar/:id?" element={<ProductCreatePage />} />
        <Route exact path="/productos/modificacion/rapida" element={<QuickUpdatePage />} />
        <Route exact path="/productos/modificacion/masiva" element={<MassiveUpdatePage />} />
        <Route exact path="/venta/historial/rubro" element={<SaleHistoryByCategoryPage />} />
        <Route exact path="/local/:name/devolucion/registrar" element={<SaleChangePage />} />
        <Route exact path="/venta/historial/producto" element={<SaleHistoryByProductPage />} />
      </Route>
    </Routes>
  )
} 
