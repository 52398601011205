import { useState } from 'react'

import { Card } from 'primereact/card'

import { UserComponent } from './components/user.component'
import { ShopComponent } from './components/shop.component'
import { BrandComponent } from './components/brand.component'
import { SettingsComponent } from './components/settings.component'
import { ProviderComponent } from './components/provider.component'
import { CategoryComponent } from './components/category.component'

import { Bookmark, Briefcase, Store, Tag, User, Settings } from 'lucide-react'

export const DashboardPage = () => {

  const [component, setComponent] = useState('Provider')

  const componentMap = {
    User: <UserComponent />,
    Shop: <ShopComponent />,
    Brand: <BrandComponent />,
    Ajustes: <SettingsComponent />,
    Provider: <ProviderComponent />,
    Category: <CategoryComponent />,
  }

  const renderedComponent = componentMap[component] || null;

  return (
    <div>
      <h2 className="text-2xl mb-1">Panel del sistema</h2>
      <span className='text-sm'>Gestiona registros y ajusta parámetros de acuerdo a tus requerimientos </span>

      <div className='md:flex gap-5 mt-5'>
        <div className='md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 h-full mb-5'>
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-1 gap-5'>
            <Card className={`!shadow-none border cursor-pointer 
            ${component === 'Provider' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('Provider')}>
              <div className='flex gap-3'>
                <Briefcase className={`text-blue-500 ${component === 'Provider' ? '!text-white' : ''}`} />
                <span className='font-medium'>Proveedores</span>
              </div>
            </Card>
            <Card className={`!shadow-none border cursor-pointer 
            ${component === 'Category' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('Category')}>
              <div className='flex gap-3'>
                <Bookmark className={`text-blue-500 ${component === 'Category' ? '!text-white' : ''}`} />
                <span className='font-medium'>Rubros</span>
              </div>
            </Card>
            <Card className={`!shadow-none border cursor-pointer 
            ${component === 'Brand' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('Brand')}>
              <div className='flex gap-3'>
                <Tag className={`text-blue-500 ${component === 'Brand' ? '!text-white' : ''}`} />
                <span className='font-medium'>Marcas</span>
              </div>
            </Card>
            <Card className={`!shadow-none border cursor-pointer 
            ${component === 'User' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('User')}>
              <div className='flex gap-3'>
                <User className={`text-blue-500 ${component === 'User' ? '!text-white' : ''}`} />
                <span className='font-medium'>Usuarios</span>
              </div>
            </Card>
            <Card className={`!shadow-none border cursor-pointer 
            ${component === 'Shop' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('Shop')}>
              <div className='flex gap-3'>
                <Store className={`text-blue-500 ${component === 'Shop' ? '!text-white' : ''}`} />
                <span className='font-medium'>Sucursales</span>
              </div>
            </Card>
            <Card className={`!shadow-none border cursor-pointer
             ${component === 'Ajustes' ? '!bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 !text-white' : ''}`}
              onClick={() => setComponent('Ajustes')}>
              <div className='flex gap-3'>
                <Settings className={`text-blue-500 ${component === 'Ajustes' ? '!text-white' : ''}`} />
                <span className='font-medium'>Ajustes</span>
              </div>
            </Card>
          </div>
        </div>
        <div className='md:w-2/3 lg:w-3/4 xl:w-4/5 2xl:w-5/6'>
          {renderedComponent}
        </div>
      </div>
    </div >
  )

}