import { useState, useEffect } from 'react'

import BrandService from '../services/brand.service'
import ProviderService from '../services/provider.service'
import CategoryService from '../services/category.service'

const brandService = new BrandService()
const categoryService = new CategoryService()
const providerService = new ProviderService()

function ProductFormFilters() {
  
  const [listProviders, setListProviders] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listBrands, setListBrands] = useState([]);
  const [listQuantities] = useState([10, 20, 30, 40, 50, "TODOS"]);

  useEffect(() => {
    const fetchProveedores =  providerService.getAll().then((res) => setListProviders(res.map(item => item.businessName)));
    const fetchRubros = categoryService.getAll().then((res) => setListCategories(res.map(item => item.description)));
    const fetchMarcas = brandService.getAll().then((res) => setListBrands(res.map(item => item.description)));

    Promise.all([fetchProveedores, fetchRubros, fetchMarcas])
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return { listProviders, listCategories, listBrands, listQuantities }
}

export default ProductFormFilters;