import { useState } from "react"

export const useForm = (initialForm = {}) => {

  const [formState, setFormState] = useState(initialForm)

  const handleDate = ({target}) => {
    const fecha = new Date(target.value).toISOString().split('T')[0]
    const { name } = target
    setFormState({
      ...formState,
      [name]: fecha
    })
  }

  const onInputChange = ({target}) => {
    const { name, value } = target
    setFormState({
      ...formState,
      [name]: value
    })
  }

  const onDropdownChange = ({target}) => {
    const { name, value } = target
    setFormState({
      ...formState,
      [name]: value
    })
  }

  return{
    formState,
    handleDate,
    setFormState,
    onInputChange,
    onDropdownChange
  }

}